import * as RNLocalize from 'react-native-localize';
import DeviceInfo from './nativeModules/DeviceInfo';
import {Platform} from 'react-native';

class Settings {
  buildType = 'default';
  appStoreUrl = 'itms://itunes.apple.com/us/app/id1087422156';
  discoveryUrl =
    'https://tally.prod.readytouchpos.com/tallyapi/discover/listAsEnvironmentsMap';
  env = 'prod';
  ipaName = '365Pay';
  appDisplayName = '365Pay';
  advertiserName = 'AVI_365P';
  webAppDisplayName = 'MyMarketAccount.com';
  key = '3C9DEDE2-EC3E-43E3-9C20-E591ED341127';
  oneSignalAppId = '2f91b1cd-736e-4bfa-a5ad-3e7e2bc23d8c';
  appInsightsKey = '6e37adae-18bc-4788-af57-4c15d17da0cb';
  branchKey = 'key_live_klupKo0d9jql3N02Yzmwjimgzum8ak7R';
  branchFallbackUrls = {"DEV":"https://365paytest3.365rm.us","QA":"https://test4-365pay.365rm.us","STAGING":"https://staging-365pay.365rm.us","PROD":"https://www.mymarketaccount.net"};
  //default 365Pay info for all env.
  auth0 = {"DEV":{"domain":"test3-auth.365rm.us","clientId":"629RtugvLUc0iqkpfAf6J1ALVgP2JYAf","clientIdAuth0Web":"saxXiP93XqynCX39hOq8QuoosetJ8tVg","audience":"https://gateway.test3.365rm.us","orgIdAuth0":"org_jksDRIsNqGyOBAjv"},"QA":{"domain":"test4-auth.365rm.us","clientId":"41kpnxsBFiu5WvCdevDu4st3gILDOpkW","clientIdAuth0Web":"w0uKDe5qW8CeX5rNeWbkVZMvHekv3Ecv","audience":"https://gateway.test4.365rm.us","orgIdAuth0":"org_IrWfAfbFQsHSD5b1"},"STAGING":{"domain":"staging-auth.365rm.us","clientId":"0WIcpc1cnqQkj4qeeBo6JOvhlwUtK014","clientIdAuth0Web":"VGlfhJJ0OmJySzbM1cp0P4OqH8QduUaY","audience":"https://gateway.staging.365rm.us","orgIdAuth0":"org_QeVusOe0rYKxy20e"},"PROD":{"domain":"auth.365rm.us","clientId":"meApfHjrLa8iBDLo0A2GAxZyww70rmaB","clientIdAuth0Web":"rK03cy4kcVwFB1CoGDKByvLEIRyHwiEq","audience":"https://gateway.prod.365rm.us","orgIdAuth0":"org_Lv9Kdj6O3W1Z82mb"}};
  scope = 'openid offline_access profile email';
  // prettier-ignore
  iOSScanditKey = 'AUSuWIGBFfUTMaMl3UPN01YKZtj3KSRSLWjuFNEZKQ0kfERNL3oYxixzHIR3Wj0WBkHGYEdz5xnuZU78z0UfUxJC2MZkc9OKzBhjP5NNy1ShMVkvEGNptkIHQhywK1AMwwfDwGEDUagiUDjsnK5gn2+qgt941XNvBgonY06pin7igVZxiw7d3I0A6BAdhacQc0N94wCdJO/04iy5X4CPzq1dqR2G7zz4zULOkpSAVakYE/jsYRdQxYawlOd6K/q+SG4tsIxcWCaJzyq1gVloJrTpsRzhHY3x6dju2YsdAUvlNBKK2teBG601734d8ujRrK488bgBB5zTJGRWUvNKwKJ81HjH35UwdAZymtzVT4XcrWlp8HSpiVJTQAfLlsca9JBcHg1V50VRcrRMWu3gQ098aNFI6NGNDyY4Z1Uz9E7IxrRU9wCbfKXNCswisb0jJmu2rKQNseZcwCErVa4bMzVCJyXd4ijkqEO0lD75x02VP8hEcjgWam+Y4C9VC9nbQCpw5BHps9pCDCrYpWObGpxcz0Hb+P2xvsWGEfGWIkTW0DHHB/KpBdGJ730y/5XbXrdnEyLm8xcON1+QJ6nV4da8O6LxGX2hNM9KE1GdQi/mwPJbj52b4adhKc00YrCrswHZocBAJJpE8FPx0BeaDN0gRwTdRp8mXChZtSA4uKySbJrIAnJVFKK5OjtiGI7aQyITa/1R+44S7rExfUqOjLeJqPukV6E7C5lQ3Kc+nnuZZ2q4VlTEZf6O4RA64HDMl5wVHKu1R3mxRg7ZaBHl5l4lxNRvcVw1qDM04NzXiGGzlOlisiFd';
  // prettier-ignore
  androidScanditKey = 'Aa7OJaaBMtATObNEdTll6N4SbsDgLLOcmGer9CwtJC22VibF0UImvJhUqF+yNQJRri9tc0E/YbTpdNf6y3c8D9MUfy+bcvz7qyKGOjJH+DJ7cKayJBnU/woxShtERmQJfweiqprqRWZqgKlGARVot8tiRwtN0oClhuJ5vdbA5l5cPOEoK77ayg88E5+E/k4ikN1NNpSGQUBeKWZhGbekaxiz0pTa/aG8XOjyAdOfzSjXlZiYvK6X8KlXPwjDVlnro0IZbjREFncOpDuff10Atv9bX8MRgVJIYGw/E3MBfmIXSRuLgTm6j8wMEPgT4f4Fz7i+LwVQY+BuFiEyGBULLPP6dSyknr68qYoZmME9UKlh0sclT41PMpNS4RFqisDFMEDkaE2jMQOXOTEDvgACrAEN1QmDJtaCiFCy0AfePTATHyuem0jl1VwylOuCmcmmdozIR5PE6ZmINVUYWuR9fb3CnVGAMBfftCL7KiilngQh2YjYT20G/Tt0jIm0ZLVOQG6q/ETFkL7/KxHf+U7U54oE5OaNVNDOsd/hdHQwoivDRwYrMA7F5v6rIt3knh4zf8b4+5NwE7QihElOnofqqOzyR6/jbAbwggMGhtK/UtQndDWWid0mRWWae4+WUoIVj2bWe0pcNCUeNVviycghv/p1QfxQjw+QTAr488NqWCIQvNtP/LrWBoQa5til9OiiyMMHBfkavQLqY3AetROvKACChYM2/UFahdvUp6VaewlNZ40HnAtOox+ois4v28KttSY9OdQ6hhTKiMXEn/Q+BX4ETvT5nV3G+pID/NLDw/bHAw==';
  demo = false;
  contactEmail = 'Support@365smartshop.com';
  supportEmail = 'Support@365smartshop.com';
  processors: Record<string, number> = {
    heartland: 14,
    stripe: 20,
  };
  processorsAsString: Record<string, string> = {
    heartland: 'heartland',
    stripe: 'stripe',
  };
  hapticOptions = {
    enableVibrateFallback: true,
    ignoreAndroidSystemSettings: false,
  };

  appTourVersion = '1.0';

  getPackageName() {
    return DeviceInfo.getBuildIdSync();
  }
  async getFullDisplayVersion(platform: string) {
    const buildNumber = await DeviceInfo.getBuildNumber();

    if (platform === 'ios') {
      return `v${buildNumber}`;
    }

    return `v${await DeviceInfo.getVersion()} (${buildNumber})`;
  }

  getPrivacyPolicyUrl(locale: string, version: string, type: string) {
    return `https://contents.365retailmarkets.com/${type}/${this.getPoliciesLocale(
      locale,
    )}/privacy/${version}/privacy.html`;
  }

  async getPrivacyPolicyHtml(
    locale: string,
    version: string,
    type: string,
  ): Promise<string> {
    const url = this.getPrivacyPolicyUrl(locale, version, type);

    try {
      const response = await fetch(url);

      if (response.status === 404) {
        throw new Error('Resource not found');
      }
      return response.text();
    } catch (error) {
      const defaultLocale = this.getDefaultLocale();

      if (locale !== defaultLocale) {
        return this.getPrivacyPolicyHtml(defaultLocale, version, type);
      } else {
        throw error;
      }
    }
  }

  getTermsUrl(locale: string, type: string) {
    return `https://contents.365retailmarkets.com/${type}/${this.getPoliciesLocale(
      locale,
    )}/terms/terms.html`;
  }

  getPoliciesLocale(locale: string) {
    if (locale.startsWith('en')) {
      return 'en-US';
    } else if (locale.startsWith('fr')) {
      return 'fr-CA';
    } else if (locale.startsWith('it')) {
      return 'it-IT';
    } else if (locale.startsWith('de')) {
      return 'de-CH';
    } else if (locale.startsWith('nl')) {
      return 'nl-NL';
    } else if (locale.startsWith('es')) {
      return 'es-ES';
    } else if (locale.startsWith('sv')) {
      return 'sv-SE';
    } else if (locale.startsWith('da')) {
      return 'da-DK';
    } else if (locale.startsWith('nb')) {
      return 'nb-NO';
    } else if (locale.startsWith('no')) {
      return 'no-NO';
    }

    return locale;
  }

  getDefaultLocale() {
    return 'en-US';
  }

  getLocale() {
    const locale = RNLocalize.getLocales()[0];
    let localeStr = locale.languageTag;

    if (localeStr.length === 2) {
      localeStr += `-${locale.countryCode}`;
    }

    return localeStr;
  }

  getLocales() {
    return RNLocalize.getLocales().map((locale) => {
      let localeStr = locale.languageTag;

      if (localeStr.length === 2) {
        localeStr += `-${locale.countryCode}`;
      }

      return localeStr;
    });
  }
  isNewUI() {
    return (
      Platform.OS == 'web' ||
      this.buildType === 'default' ||
      this.buildType === 'canteen' ||
      this.buildType === 'companykitchen' ||
      this.buildType === 'refive'
    );
  }

  is365Pay() {
    return Platform.OS == 'web' || this.buildType === 'default';
  }
  isCanteen() {
    return (
      Platform.OS !== 'web' &&
      (this.buildType === 'canteen' || this.buildType === 'companykitchen')
    );
  }

  isRefive() {
    return Platform.OS !== 'web' && this.buildType === 'refive';
  }

  isRefiveAnd365Pay() {
    return (
      Platform.OS == 'web' || ['default', 'refive'].indexOf(this.buildType) > -1
    );
  }
}

export default new Settings();
