import RewardsScreen from 'src/components/screens/RewardsScreen';
import RewardsLegacyScreen from 'src/components/screens/RewardsLegacyScreen';
import AccountStore from 'src/stores/AccountStore';
import {NavigationProp, RouteProp} from '@react-navigation/native';
import {useEffect, useState} from 'react';
import NetInfo from '@react-native-community/netinfo';
import LoadingScreen from 'src/components/screens/LoadingScreen';

const withScreenData = (GMARewardsScreen, LegacyRewardsScreen) => {
  return (props: {navigation: NavigationProp<any>; route: RouteProp<any>}) => {
    const [, setIsConnected] = useState(true);
    const [loading, setLoading] = useState(true); // Track loading state

    // Check for network status
    useEffect(() => {
      const unsubscribe = NetInfo.addEventListener((state) => {
        setIsConnected(state.isConnected);
      });
      return () => unsubscribe();
    }, []);

    useEffect(() => {
      const fetchData = async () => {
        await new Promise((resolve) => setTimeout(resolve, 5000)); // Delay for demonstration
        setLoading(false);
      };

      fetchData();
    }, []);

    if (loading) {
      return <LoadingScreen />;
    } else {
      if (
        AccountStore.getAccountRewardPrograms() &&
        Object.keys(AccountStore.getAccountRewardPrograms()).length > 0 &&
        AccountStore.getRewardStatus() &&
        Object.keys(AccountStore.getRewardStatus()).length > 0 &&
        AccountStore.getRewardStatus()?.hasOwnProperty('rewardTier') &&
        AccountStore.getRewardStatus()?.hasOwnProperty('rewardStatuses') &&
        AccountStore.getRewardProducts() &&
        Object.keys(AccountStore.getRewardProducts()).length > 0
      ) {
        return <GMARewardsScreen {...props} />;
      } else if (
        AccountStore.getAccountRewards() > 0 ||
        AccountStore.isDemo()
      ) {
        return <LegacyRewardsScreen {...props} />;
      } else {
        return <></>;
      }
    }
  };
};

export const RewardsTabScreenWithHOC = withScreenData(
  RewardsScreen,
  RewardsLegacyScreen,
);
