import {getDescriber as getSnackDescriber} from 'src/components/screens/sendSnack/descriptor/sendasnack/DescriptorType';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';

import Styles from '../../Styles';
import {Platform} from 'react-native';
import React from 'react';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import Localized from 'src/constants/AppStrings';
import Svg, {
  Path,
  Defs,
  LinearGradient,
  Stop,
  ClipPath,
  G,
} from 'react-native-svg';
import AVText from 'src/components/elements/AVText';
import AVFormattedCurrency from 'src/components/elements/AVFormattedCurrency';

const backgroundColor = Platform.select({
  web: 'white',
  default: Styles.loginBackground,
});

export function getTrailBlazerIcon(size, props) {
  return (
    <Svg
      width={size ?? 183}
      height={size ?? 182}
      viewBox="0 0 183 182"
      fill="none"
      {...props}
    >
      <Path
        d="M91.5 177.45c47.745 0 86.45-38.705 86.45-86.45 0-47.745-38.705-86.45-86.45-86.45C43.755 4.55 5.05 43.255 5.05 91c0 47.745 38.705 86.45 86.45 86.45z"
        fill="url(#paint0_linear_5935_30135)"
      />
      <Path
        d="M91.5 163.8c40.583 0 73.482-32.899 73.482-73.483 0-40.583-32.899-73.482-73.482-73.482-40.584 0-73.483 32.9-73.483 73.482 0 40.584 32.9 73.483 73.483 73.483z"
        fill="#F3F3F3"
      />
      <Path
        d="M123.486 121.371h-64.61a5.3 5.3 0 00-5.3 5.301v5.414a5.3 5.3 0 005.3 5.301h64.61a5.3 5.3 0 005.301-5.301v-5.414a5.3 5.3 0 00-5.301-5.301z"
        fill="url(#paint1_linear_5935_30135)"
      />
      <Path
        d="M123.463 134.316h-64.61c-2.912 0-5.3-2.389-5.3-5.301v3.071c0 2.912 2.389 5.301 5.3 5.301h64.61c2.912 0 5.301-2.389 5.301-5.301v-3.071c0 2.912-2.389 5.301-5.301 5.301z"
        fill="#60A09C"
        opacity={0.4}
      />
      <Path
        d="M80.694 119.665h-4.232c-.227 0-.41.159-.41.364v21.362c0 .614 1.661 2.684 1.661 2.684.387.592 1.343.592 1.73 0 0 0 1.66-2.07 1.66-2.684v-21.362c0-.205-.182-.364-.41-.364z"
        fill="#409F68"
      />
      <Path
        d="M78.6 133.428h-.068c-.09 0-.159-.091-.159-.204v-2.253c0-.068.114-.295.114-.295.023-.069.09-.069.136 0 0 0 .114.227.114.295v2.253c0 .113-.068.204-.16.204h.023z"
        fill="#E7E7E7"
      />
      <Path
        d="M81.399 131.335a.3.3 0 00-.296-.295h-5.096a.3.3 0 00-.296.295v5.529a.3.3 0 00.296.295.3.3 0 00.296-.295v-5.233h4.436v5.233a.3.3 0 00.296.295.3.3 0 00.296-.295s.068-5.46.068-5.551v.022z"
        fill="#E7E7E7"
      />
      <Path
        d="M81.217 136.932h-5.3a.773.773 0 00-.774.773v.455c0 .427.346.774.773.774h5.3a.774.774 0 00.774-.774v-.455a.773.773 0 00-.773-.773zM81.217 127.513h-5.3a.773.773 0 00-.774.774v.455c0 .427.346.773.773.773h5.3a.773.773 0 00.774-.773v-.455a.773.773 0 00-.773-.774z"
        fill="#E7E7E7"
      />
      <Path
        d="M99.803 120.279h4.232c.228 0 .41.159.41.341v20.839c0 .614-1.661 2.616-1.661 2.616-.387.569-1.342.569-1.729 0 0 0-1.661-2.002-1.661-2.616V120.62c0-.182.182-.341.41-.341z"
        fill="#409F68"
      />
      <Path
        d="M101.896 133.428h.069c.091 0 .159-.091.159-.204v-2.253c0-.068-.114-.295-.114-.295-.023-.069-.091-.069-.136 0 0 0-.114.227-.114.295v2.253c0 .113.068.204.159.204h-.023z"
        fill="#E7E7E7"
      />
      <Path
        d="M99.075 131.335a.3.3 0 01.296-.295h5.096c.159 0 .296.136.296.295v5.529a.301.301 0 01-.296.295.301.301 0 01-.296-.295v-5.233h-4.436v5.233a.3.3 0 01-.296.295.3.3 0 01-.296-.295s-.068-5.46-.068-5.551v.022z"
        fill="#E7E7E7"
      />
      <Path
        d="M99.257 138.934h5.301a.775.775 0 00.774-.774v-.455a.774.774 0 00-.774-.773h-5.3a.773.773 0 00-.774.773v.455c0 .427.346.774.773.774zM99.257 129.697h5.301a.774.774 0 00.774-.773v-.455a.775.775 0 00-.774-.774h-5.3a.774.774 0 00-.774.774v.455c0 .427.346.773.773.773z"
        fill="#E7E7E7"
      />
      <Path
        d="M90.612 37.333c-3.39 0-6.165 4.982-6.165 11.147h1.365c0-5.437 2.139-9.873 4.778-9.873s4.777 4.413 4.777 9.873h1.365c0-6.165-2.752-11.147-6.142-11.147h.022z"
        fill="#003780"
      />
      <Path
        d="M79.153 34.406l-3.306.745a3.117 3.117 0 00-2.356 3.726l2.781 12.34a3.117 3.117 0 003.726 2.355l3.307-.745A3.117 3.117 0 0085.66 49.1l-2.78-12.34a3.117 3.117 0 00-3.727-2.355zM97.838 52.816l3.307.745a3.117 3.117 0 003.726-2.355l2.781-12.34a3.117 3.117 0 00-2.356-3.726l-3.307-.745a3.116 3.116 0 00-3.725 2.355l-2.781 12.34a3.117 3.117 0 002.355 3.726z"
        fill="#003780"
      />
      <Path
        d="M118.049 117.617h-3.412a5.427 5.427 0 01-5.415-5.414V95.459c0-1.16.933-2.093 2.093-2.093h10.078c1.161 0 2.093.932 2.093 2.093v16.744a5.427 5.427 0 01-5.414 5.414h-.023z"
        fill="url(#paint2_linear_5935_30135)"
      />
      <Path
        d="M114.818 90.863h4.55a4.835 4.835 0 014.846 4.846v2.116a1.874 1.874 0 01-1.865 1.865h-10.511a1.873 1.873 0 01-1.865-1.865v-2.116a4.835 4.835 0 014.845-4.846z"
        fill="#409F68"
      />
      <Path
        d="M60.014 93.297h10.078c1.16 0 2.093.933 2.093 2.093v16.744a5.427 5.427 0 01-5.415 5.415h-3.412a5.427 5.427 0 01-5.415-5.415V95.39c0-1.16.933-2.093 2.093-2.093h-.022z"
        fill="url(#paint3_linear_5935_30135)"
      />
      <Path
        d="M69.569 99.622h-10.51a1.874 1.874 0 01-1.866-1.865V95.64a4.835 4.835 0 014.845-4.846h4.55a4.835 4.835 0 014.846 4.846v2.116a1.874 1.874 0 01-1.865 1.865z"
        fill="#409F68"
      />
      <Path
        d="M84.424 50.573h12.354c10.806 0 19.565 8.758 19.565 19.565v44.613a6.913 6.913 0 01-6.916 6.916H71.775a6.913 6.913 0 01-6.916-6.916V70.138c0-10.807 8.76-19.565 19.565-19.565z"
        fill="#003349"
      />
      <Path
        d="M109.427 118.208H71.775c-3.799 0-6.916-3.116-6.916-6.916v3.436c0 3.799 3.117 6.916 6.916 6.916h37.652c3.799 0 6.916-3.117 6.916-6.916v-3.436c0 3.8-3.117 6.916-6.916 6.916z"
        fill="#F8A326"
        opacity={0.4}
      />
      <Path
        d="M71.753 45.682h37.742a3.155 3.155 0 013.162 3.162v10.078c0 6.598-5.346 11.944-11.944 11.944H80.534c-6.597 0-11.944-5.346-11.944-11.944V48.844a3.156 3.156 0 013.163-3.162z"
        fill="url(#paint4_linear_5935_30135)"
      />
      <Path
        d="M109.472 45.682H71.73c-1.729 0-3.162 1.433-3.162 3.162v1.524c0-1.729 1.433-3.162 3.162-3.162h37.742c1.729 0 3.163 1.433 3.163 3.162v-1.524c0-1.73-1.434-3.162-3.163-3.162z"
        fill="#BE4D27"
        opacity={0.3}
      />
      <Path
        d="M71.753 91.591h37.742a3.155 3.155 0 013.162 3.163v15.037c0 6.598-5.346 11.944-11.944 11.944H80.534c-6.597 0-11.944-5.346-11.944-11.944V94.754a3.156 3.156 0 013.163-3.163z"
        fill="#409F68"
      />
      <Path
        d="M71.412 91.591h38.402a2.845 2.845 0 012.843 2.844v4.277c0 5.915-4.8 10.693-10.692 10.693h-22.66c-5.914 0-10.692-4.801-10.692-10.693v-4.277a2.845 2.845 0 012.844-2.844h-.045z"
        fill="url(#paint5_linear_5935_30135)"
      />
      <Path
        d="M100.85 75.12H80.375a3.117 3.117 0 00-3.117 3.117v5.687a3.117 3.117 0 003.117 3.117h20.475a3.117 3.117 0 003.117-3.117v-5.687a3.117 3.117 0 00-3.117-3.117z"
        fill="url(#paint6_linear_5935_30135)"
      />
      <Path
        d="M92.82 57.898h-4.437c-.228 0-.432.182-.432.387v23.751c0 .705 1.729 2.98 1.729 2.98.41.66 1.41.66 1.797 0 0 0 1.729-2.297 1.729-2.98v-23.75c0-.228-.182-.388-.432-.388h.045z"
        fill="#409F68"
      />
      <Path
        d="M90.635 70.525h-.068c-.091 0-.16-.091-.16-.228v-2.343c0-.068.137-.318.137-.318a.071.071 0 01.137 0s.136.25.136.318v2.343c0 .114-.068.228-.159.228h-.023z"
        fill="#E7E7E7"
      />
      <Path
        d="M93.57 68.318a.325.325 0 00-.319-.319h-5.3c-.16 0-.32.16-.32.319v5.779c0 .159.137.318.32.318a.311.311 0 00.318-.319v-5.46h4.618v5.46c0 .16.137.319.319.319a.311.311 0 00.318-.319l.068-5.778h-.022z"
        fill="#E7E7E7"
      />
      <Path
        d="M93.41 74.165h-5.573a.796.796 0 00-.797.796v.5c0 .44.357.797.797.797h5.573c.44 0 .797-.357.797-.797v-.5a.796.796 0 00-.796-.796zM93.41 62.585h-5.573a.796.796 0 00-.797.796v.5c0 .44.357.797.797.797h5.573c.44 0 .797-.356.797-.796v-.5a.796.796 0 00-.796-.797zM90.703 78.533a.455.455 0 100-.91.455.455 0 000 .91zM90.703 80.58a.455.455 0 100-.91.455.455 0 000 .91zM90.703 82.605a.455.455 0 100-.91.455.455 0 000 .91z"
        fill="#E7E7E7"
      />
      <Path
        d="M81.126 90.909h-4.232c-.227 0-.41.182-.41.432v25.707c0 .751 1.662 3.231 1.662 3.231a.971.971 0 001.729 0s1.66-2.48 1.66-3.231V91.341c0-.228-.182-.432-.41-.432z"
        fill="#409F68"
      />
      <Path
        d="M79.033 109.746h-.069c-.09 0-.159-.091-.159-.205v-2.252c0-.068.114-.296.114-.296.023-.068.09-.068.136 0 0 0 .114.228.114.296v2.252c0 .114-.068.205-.16.205h.024z"
        fill="#E7E7E7"
      />
      <Path
        d="M81.853 107.653a.3.3 0 00-.295-.296h-5.096a.3.3 0 00-.296.296v5.528c0 .159.136.296.296.296.159 0 .295-.137.295-.296v-5.233h4.437v5.233c0 .159.136.296.296.296.159 0 .295-.137.295-.296 0 0 .069-5.46.069-5.551v.023z"
        fill="#E7E7E7"
      />
      <Path
        d="M81.649 113.227h-5.3a.773.773 0 00-.774.773v.455c0 .427.346.774.773.774h5.301a.774.774 0 00.773-.774V114a.773.773 0 00-.773-.773zM81.649 102.147h-5.3a.773.773 0 00-.774.773v.455c0 .428.346.774.773.774h5.301a.773.773 0 00.773-.774v-.455a.773.773 0 00-.773-.773zM81.649 97.142h-5.3a.773.773 0 00-.774.774v.455c0 .427.346.773.773.773h5.301a.773.773 0 00.773-.773v-.455a.773.773 0 00-.773-.774z"
        fill="#E7E7E7"
      />
      <Path
        d="M100.236 90.909h4.231c.228 0 .41.182.41.432v25.707c0 .751-1.661 3.231-1.661 3.231a.971.971 0 01-1.729 0s-1.66-2.48-1.66-3.231V91.341c0-.228.181-.432.409-.432z"
        fill="#409F68"
      />
      <Path
        d="M102.329 109.746h.068c.091 0 .159-.091.159-.205v-2.252c0-.068-.113-.296-.113-.296-.023-.068-.091-.068-.137 0 0 0-.114.228-.114.296v2.252c0 .114.069.205.16.205h-.023z"
        fill="#E7E7E7"
      />
      <Path
        d="M99.53 107.653a.3.3 0 01.296-.296h5.096c.159 0 .296.136.296.296v5.528a.302.302 0 01-.296.296.302.302 0 01-.296-.296v-5.233h-4.436v5.233a.301.301 0 01-.296.296.302.302 0 01-.296-.296s-.068-5.46-.068-5.551v.023z"
        fill="#E7E7E7"
      />
      <Path
        d="M99.712 115.251h5.301a.774.774 0 00.774-.774v-.455a.774.774 0 00-.774-.773h-5.3a.773.773 0 00-.774.773v.455c0 .428.346.774.773.774zM99.712 104.149h5.301a.774.774 0 00.774-.773v-.455a.775.775 0 00-.774-.774h-5.3a.774.774 0 00-.774.774v.455c0 .427.346.773.773.773zM99.712 99.53h5.301a.774.774 0 00.774-.773v-.455a.774.774 0 00-.774-.773h-5.3a.773.773 0 00-.774.773v.455c0 .427.346.774.773.774z"
        fill="#E7E7E7"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_5935_30135"
          x1={5.0498}
          y1={90.9998}
          x2={177.95}
          y2={90.9998}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_5935_30135"
          x1={53.5757}
          y1={129.356}
          x2={128.764}
          y2={129.356}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint2_linear_5935_30135"
          x1={109.222}
          y1={105.491}
          x2={123.464}
          y2={105.491}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint3_linear_5935_30135"
          x1={57.9434}
          y1={105.4}
          x2={72.1849}
          y2={105.4}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint4_linear_5935_30135"
          x1={68.5676}
          y1={58.2624}
          x2={112.634}
          y2={58.2624}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint5_linear_5935_30135"
          x1={68.5678}
          y1={100.487}
          x2={112.635}
          y2={100.487}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint6_linear_5935_30135"
          x1={77.2583}
          y1={81.0806}
          x2={103.967}
          y2={81.0806}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
export function getRedeemColor() {
  return {
    backgroundColor: '#003349',
    borderTopColor: '#003349',
    borderBottomColor: '#003349',
    borderLeftColor: '#003349',
    borderRightColor: '#003349',
    borderColor: '#003349',
  };
}
export function renderPointsToNextRewardLbl(_styles, nextPoint) {
  const morePointsThanHigher = nextPoint < 0 ? true : false;
  return (
    <>
      <AVText
        style={_styles.pointsToNextRwd}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
        testID={Localized.Labels.redeem}
        adjustsFontSizeToFit={true}
      >
        {!morePointsThanHigher
          ? Localized.Labels.formatString(
              Localized.Labels.points_next_rewrd,
              nextPoint,
            )
          : Localized.Labels.more_points_than_available}
      </AVText>
    </>
  );
}
export function getSmartShopper(size, props) {
  return (
    <Svg
      width={size ?? 183}
      height={size ?? 182}
      viewBox="0 0 183 182"
      fill="none"
      {...props}
    >
      <Path
        d="M91.5 177.45c47.745 0 86.45-38.705 86.45-86.45 0-47.745-38.705-86.45-86.45-86.45C43.755 4.55 5.05 43.255 5.05 91c0 47.745 38.705 86.45 86.45 86.45z"
        fill="url(#paint0_linear_5935_30024)"
      />
      <Path
        d="M91.5 164.483c40.583 0 73.483-32.9 73.483-73.483 0-40.583-32.9-73.482-73.483-73.482-40.583 0-73.482 32.899-73.482 73.482s32.899 73.483 73.482 73.483z"
        fill="#F3F3F3"
      />
      <Path
        d="M126.262 74.78c0-19.816-16.585-35.741-36.628-34.695-17.95.956-32.464 15.97-32.85 33.943-.251 11.876 5.46 22.41 14.354 28.87 1.843 1.342 3.004 3.435 3.004 5.71v.774c0 2.07 1.547 3.617 3.48 3.617h27.801a3.481 3.481 0 003.481-3.481v-.932c0-2.275 1.137-4.346 2.98-5.688 8.713-6.302 14.401-16.54 14.401-28.142h-.023v.023z"
        fill="#D5DDF5"
      />
      <Path
        d="M126.262 74.78h1.251c0-9.943-4.027-18.952-10.556-25.458A35.898 35.898 0 0091.5 38.789c-.637 0-1.274 0-1.934.045-9.305.5-17.722 4.641-23.842 10.966-6.12 6.324-9.987 14.878-10.192 24.206v.773c0 5.983 1.456 11.648 4.05 16.608a36.157 36.157 0 0010.829 12.535 6.161 6.161 0 011.82 2.093c.432.796.66 1.683.66 2.616v.774c0 1.342.5 2.57 1.364 3.458a4.95 4.95 0 001.502 1.046c.569.25 1.206.364 1.843.364h27.8c1.297 0 2.48-.523 3.344-1.388a4.669 4.669 0 001.388-3.344v-.933c0-.932.228-1.82.66-2.616a6.301 6.301 0 011.82-2.07 36.427 36.427 0 0010.852-12.513 35.817 35.817 0 004.072-16.63v-3.026l-2.161 2.162.887.887.887.887h.023l-.887-.91h-1.252c0 5.597-1.365 10.852-3.799 15.47a33.441 33.441 0 01-10.101 11.648 8.692 8.692 0 00-2.548 2.912 7.958 7.958 0 00-.955 3.777v.932c0 .615-.251 1.183-.66 1.57a2.2 2.2 0 01-1.57.66H77.6c-.319 0-.614-.068-.865-.182a2.262 2.262 0 01-.978-.819c-.25-.364-.387-.819-.387-1.365v-.774a8.11 8.11 0 00-.955-3.799 8.805 8.805 0 00-2.571-2.935 33.456 33.456 0 01-10.078-11.67 33.28 33.28 0 01-3.777-15.448v-.728c.182-8.645 3.777-16.607 9.487-22.5 5.71-5.914 13.536-9.76 22.181-10.214.615-.023 1.206-.046 1.797-.046 9.26 0 17.632 3.754 23.706 9.806 6.074 6.074 9.805 14.423 9.805 23.682v3.026l2.139-2.138-.888-.888.046.023z"
        fill="url(#paint1_linear_5935_30024)"
      />
      <Path
        d="M77.623 127.832v1.252H105.4a2.2 2.2 0 011.57.659c.41.41.66.956.66 1.57a2.2 2.2 0 01-.66 1.57c-.409.409-.955.66-1.57.66H77.623c-.615 0-1.16-.251-1.57-.66-.41-.41-.66-.956-.66-1.57 0-.614.25-1.16.66-1.57.41-.409.955-.659 1.57-.659v-2.503a4.67 4.67 0 00-3.345 1.388 4.672 4.672 0 00-1.387 3.344 4.71 4.71 0 001.387 3.344 4.767 4.767 0 003.345 1.388H105.4a4.673 4.673 0 003.345-1.388 4.672 4.672 0 001.387-3.344c0-1.297-.523-2.48-1.387-3.344a4.769 4.769 0 00-3.345-1.388H77.623v1.251z"
        fill="url(#paint2_linear_5935_30024)"
      />
      <Path
        d="M98.462 112.999H94.98v-29.53h-6.939V113h-3.48v-29.53h-4.346c-4.3 0-7.826-3.503-7.826-7.825a7.826 7.826 0 017.826-7.826 7.826 7.826 0 017.826 7.826v4.345h6.939v-4.345c0-4.3 3.503-7.826 7.826-7.826a7.826 7.826 0 110 15.652h-4.345v29.529zm0-33.01h4.345a4.36 4.36 0 004.345-4.345 4.36 4.36 0 00-4.345-4.345 4.36 4.36 0 00-4.345 4.345v4.345zm-18.246-8.668a4.36 4.36 0 00-4.345 4.346 4.36 4.36 0 004.345 4.345h4.345v-4.345a4.36 4.36 0 00-4.345-4.346z"
        fill="url(#paint3_linear_5935_30024)"
      />
      <Path
        d="M77.623 120.439v1.251H105.4c.615 0 1.161.25 1.57.66a2.197 2.197 0 010 3.139c-.409.41-.955.66-1.57.66H77.623c-.615 0-1.16-.25-1.57-.66a2.199 2.199 0 010-3.139c.41-.41.955-.66 1.57-.66v-2.502a4.674 4.674 0 00-3.345 1.387 4.674 4.674 0 00-1.387 3.344c0 1.297.523 2.48 1.387 3.345a4.766 4.766 0 003.345 1.387H105.4a4.676 4.676 0 003.345-1.387 4.676 4.676 0 001.387-3.345 4.715 4.715 0 00-1.387-3.344 4.768 4.768 0 00-3.345-1.387H77.623v1.251z"
        fill="url(#paint4_linear_5935_30024)"
      />
      <Path
        d="M77.623 113.022v1.251H105.4c.615 0 1.161.25 1.57.66a2.197 2.197 0 010 3.139c-.409.41-.955.66-1.57.66H77.623c-.615 0-1.16-.25-1.57-.66a2.199 2.199 0 010-3.139c.41-.41.955-.66 1.57-.66v-2.502a4.674 4.674 0 00-3.345 1.387 4.675 4.675 0 00-1.387 3.345c0 1.296.523 2.479 1.387 3.344a4.767 4.767 0 003.345 1.388H105.4a4.677 4.677 0 003.345-1.388 4.675 4.675 0 001.387-3.344c0-1.297-.523-2.48-1.387-3.345a4.772 4.772 0 00-3.345-1.387H77.623v1.251z"
        fill="url(#paint5_linear_5935_30024)"
      />
      <Path
        d="M81.08 135.931h20.84a6.938 6.938 0 01-6.94 6.939h-6.938a6.937 6.937 0 01-6.939-6.939h-.022z"
        fill="#4D4D4D"
      />
      <Path
        d="M118.027 58.217l-2.935 1.843a26.713 26.713 0 012.434 4.914l3.253-1.229a32 32 0 00-2.73-5.528h-.022zM100.918 48.617a27.927 27.927 0 0110.215 6.484l2.457-2.457a31.436 31.436 0 00-11.511-7.303l-1.183 3.276h.022z"
        fill="#fff"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_5935_30024"
          x1={5.0498}
          y1={90.9998}
          x2={177.95}
          y2={90.9998}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_5935_30024"
          x1={55.5322}
          y1={70.1836}
          x2={127.536}
          y2={70.1836}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint2_linear_5935_30024"
          x1={72.8906}
          y1={131.29}
          x2={110.132}
          y2={131.29}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint3_linear_5935_30024"
          x1={72.3901}
          y1={90.4086}
          x2={110.633}
          y2={90.4086}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint4_linear_5935_30024"
          x1={72.8906}
          y1={123.897}
          x2={110.132}
          y2={123.897}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint5_linear_5935_30024"
          x1={72.8906}
          y1={116.48}
          x2={110.132}
          y2={116.48}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
export function getRewardsIcon(size) {
  return (
    <Svg width={size ?? 68} height="67" viewBox="0 0 68 67" fill="none">
      <Path
        d="M8.5 33.5C8.5 19.969 19.469 9 33 9h2c13.531 0 24.5 10.969 24.5 24.5S48.531 58 35 58h-2C19.469 58 8.5 47.031 8.5 33.5z"
        fill="#003349"
        fillOpacity={0.15}
      />
      <G clipPath="url(#clip0_9290_2034)">
        <Path
          d="M32.73 22.341A4.759 4.759 0 0028.631 20h-.116a4.64 4.64 0 00-4.135 6.75h-1.35a2.532 2.532 0 00-2.531 2.531v3.375c0 1.102.707 2.041 1.688 2.39V43.624A3.378 3.378 0 0025.563 47h16.875a3.378 3.378 0 003.374-3.375v-8.58a2.538 2.538 0 001.688-2.389v-3.375a2.532 2.532 0 00-2.531-2.531h-1.35A4.64 4.64 0 0039.485 20h-.117a4.759 4.759 0 00-4.097 2.341L34 24.51l-1.27-2.162v-.006zm12.239 6.94v3.375h-9.281v-3.375h9.281zm-12.657 0v3.375h-9.281v-3.375h9.282zm0 5.907v9.28h-6.75a.846.846 0 01-.843-.843v-8.438h7.593zm3.376 9.28v-9.28h7.593v8.437c0 .464-.38.844-.843.844h-6.75zm3.796-17.718H35.62l1.835-3.122a2.223 2.223 0 011.914-1.097h.116a2.109 2.109 0 110 4.219zm-7.172 0h-3.796a2.109 2.109 0 110-4.219h.116c.785 0 1.518.417 1.914 1.097l1.835 3.122h-.069z"
          fill="#003349"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_9290_2034">
          <Path fill="#fff" transform="translate(20.5 20)" d="M0 0H27V27H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export function renderYourBalanceRewardLbl(_styles, _accountStr) {
  return (
    <>
      <AVText
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
        style={_styles.payBalance}
      >
        {`${Localized.Labels.your_balance}: `}
        <AVFormattedCurrency
          testID={'balanceFormattedCurrency'}
          accessibilityLabel={
            _accountStr.getAccountBalance()
              ? `$${_accountStr.getAccountBalance().toString()}`
              : '0'
          }
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          style={_styles.payBalance}
          value={_accountStr.getAccountBalance() ?? 0}
          currency={_accountStr.getCurrency()}
        />
      </AVText>
    </>
  );
}
export function getLetsGoStyle() {
  return {textTransform: 'capitalize'};
}
export function getMemberTier(size, props) {
  return (
    <Svg
      width={size ?? 183}
      height={size ?? 182}
      viewBox="0 0 183 182"
      fill="none"
      {...props}
    >
      <Path
        d="M91.5 177.45c47.745 0 86.45-38.705 86.45-86.45 0-47.745-38.705-86.45-86.45-86.45C43.755 4.55 5.05 43.255 5.05 91c0 47.745 38.705 86.45 86.45 86.45z"
        fill="url(#paint0_linear_5935_30079)"
      />
      <Path
        d="M91.5 163.8c40.583 0 73.483-32.899 73.483-73.483 0-40.583-32.9-73.482-73.483-73.482-40.583 0-73.482 32.9-73.482 73.482 0 40.584 32.899 73.483 73.482 73.483z"
        fill="#F3F3F3"
      />
      <Path
        d="M116.843 106.925a7.249 7.249 0 00-1.115 3.026c-.659 4.823-5.687 7.735-10.214 5.892a7.427 7.427 0 00-8.713 2.343 7.406 7.406 0 01-5.188 2.844l-.705 1.251 15.743 27.55 8.258-14.696 17.927-.273-11.102-19.429-4.868-8.508h-.023z"
        fill="url(#paint1_linear_5935_30079)"
      />
      <Path
        d="M85.016 118.164a7.492 7.492 0 00-8.713-2.344c-4.505 1.843-9.555-1.069-10.215-5.892a7.576 7.576 0 00-1.115-3.026v.046c-.022 0-4.868 8.463-4.868 8.463l-11.102 19.428 17.199.797 8.963 14.15 15.743-27.55-.705-1.251a7.45 7.45 0 01-5.187-2.844v.023z"
        fill="url(#paint2_linear_5935_30079)"
      />
      <Path
        d="M90.203 121.03l.706 1.251.705-1.251a7.625 7.625 0 01-1.433 0h.022z"
        fill="url(#paint3_linear_5935_30079)"
      />
      <Path
        d="M90.203 121.03l.706 1.251.705-1.251a7.625 7.625 0 01-1.433 0h.022z"
        fill="url(#paint4_linear_5935_30079)"
      />
      <Path
        d="M90.908 83.606l9.623 5.506 7.03 4.027-12.444 21.771-3.504 6.143a7.451 7.451 0 005.188-2.844 7.492 7.492 0 018.713-2.343c4.504 1.843 9.555-1.069 10.214-5.892a7.578 7.578 0 011.115-3.026l-16.425-28.756-9.51 5.437v-.023z"
        fill="url(#paint5_linear_5935_30079)"
      />
      <Path
        d="M76.303 115.843a7.427 7.427 0 018.713 2.343 7.406 7.406 0 005.187 2.844L74.256 93.116l16.653-9.51-9.51-5.437-4.413 7.735-12.012 21.021a7.245 7.245 0 011.114 3.026c.66 4.823 5.688 7.735 10.215 5.892z"
        fill="url(#paint6_linear_5935_30079)"
      />
      <Path
        d="M107.561 93.116l-7.029-4.027-9.624-5.505-16.653 9.509 15.948 27.914a7.46 7.46 0 001.433 0l3.504-6.142 12.444-21.772-.023.023z"
        fill="url(#paint7_linear_5935_30079)"
      />
      <Path
        d="M107.561 93.116l-7.029-4.027-9.624-5.505-16.653 9.509 15.948 27.914a7.46 7.46 0 001.433 0l3.504-6.142 12.444-21.772-.023.023z"
        fill="url(#paint8_linear_5935_30079)"
      />
      <Path
        d="M96.8 34.921a7.492 7.492 0 008.714 2.343c4.504-1.842 9.555 1.07 10.215 5.893.455 3.321 3.071 5.937 6.37 6.37 4.823.66 7.735 5.687 5.892 10.214a7.427 7.427 0 002.343 8.714c3.845 2.98 3.845 8.804 0 11.784a7.492 7.492 0 00-2.343 8.713c1.843 4.505-1.069 9.555-5.892 10.215-3.322.455-5.938 3.071-6.37 6.37-.66 4.823-5.688 7.735-10.215 5.892a7.427 7.427 0 00-8.713 2.344c-2.98 3.844-8.805 3.844-11.785 0a7.492 7.492 0 00-8.713-2.344c-4.505 1.843-9.555-1.069-10.215-5.892-.455-3.321-3.071-5.938-6.37-6.37-4.823-.66-7.735-5.687-5.892-10.215a7.427 7.427 0 00-2.343-8.713c-3.845-2.98-3.845-8.804 0-11.784a7.492 7.492 0 002.343-8.714c-1.843-4.504 1.07-9.555 5.892-10.214 3.322-.455 5.938-3.072 6.37-6.37.66-4.823 5.688-7.735 10.215-5.893a7.427 7.427 0 008.713-2.343c2.98-3.845 8.804-3.845 11.785 0z"
        fill="url(#paint9_linear_5935_30079)"
      />
      <Path
        d="M114.933 74.347c0 12.877-10.443 23.319-23.296 23.319-12.854 0-8.827-1.32-12.422-3.572-6.552-4.118-10.897-11.42-10.897-19.724 0-12.877 10.442-23.296 23.296-23.296s10.783 2.002 14.81 5.3c5.187 4.277 8.486 10.738 8.486 17.996l.023-.023z"
        fill="#F3F3F3"
      />
      <Path
        d="M105.981 88.642c7.898-7.898 7.898-20.704 0-28.602s-20.704-7.898-28.602 0c-7.899 7.898-7.899 20.704 0 28.602 7.898 7.898 20.704 7.898 28.602 0z"
        fill="url(#paint10_linear_5935_30079)"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_5935_30079"
          x1={5.0498}
          y1={90.9998}
          x2={177.95}
          y2={90.9998}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_5935_30079"
          x1={73.6637}
          y1={128.378}
          x2={132.222}
          y2={128.378}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint2_linear_5935_30079"
          x1={49.0029}
          y1={128.378}
          x2={107.561}
          y2={128.378}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint3_linear_5935_30079"
          x1={0.500331}
          y1={121.644}
          x2={0.727831}
          y2={121.644}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint4_linear_5935_30079"
          x1={49.0032}
          y1={121.644}
          x2={107.562}
          y2={121.644}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint5_linear_5935_30079"
          x1={0.4997}
          y1={99.5996}
          x2={0.7272}
          y2={99.5996}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint6_linear_5935_30079"
          x1={49.0031}
          y1={99.5994}
          x2={107.562}
          y2={99.5994}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint7_linear_5935_30079"
          x1={0.499867}
          y1={102.33}
          x2={0.727367}
          y2={102.33}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint8_linear_5935_30079"
          x1={49.0029}
          y1={102.329}
          x2={107.561}
          y2={102.329}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint9_linear_5935_30079"
          x1={48.5934}
          y1={74.3469}
          x2={133.223}
          y2={74.3469}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint10_linear_5935_30079"
          x1={71.4416}
          y1={74.3513}
          x2={111.891}
          y2={74.3513}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
export function getEliteBuyerTier(size, props) {
  return (
    <Svg
      width={size ?? 183}
      height={size ?? 182}
      viewBox="0 0 183 182"
      fill="none"
      {...props}
    >
      <Path
        d="M91.5 177.45c47.745 0 86.45-38.705 86.45-86.45 0-47.745-38.705-86.45-86.45-86.45C43.755 4.55 5.05 43.255 5.05 91c0 47.745 38.705 86.45 86.45 86.45z"
        fill="url(#paint0_linear_5935_30191)"
      />
      <Path
        d="M91.728 164.483c40.583 0 73.482-32.9 73.482-73.483 0-40.583-32.899-73.482-73.482-73.482-40.584 0-73.483 32.899-73.483 73.482s32.9 73.483 73.483 73.483z"
        fill="#F3F3F3"
      />
      <Path
        d="M105.082 147.193h-.114c-.319 0-8.008-.592-17.495-4.141-12.626-4.732-22.386-12.581-28.21-22.682-9.737-16.88-12.285-34.375-12.717-46.068-.455-12.672 1.342-21.272 1.433-21.636a1.54 1.54 0 011.843-1.183 1.54 1.54 0 011.183 1.843c0 .091-1.82 8.645-1.342 20.953.432 11.33 2.912 28.233 12.307 44.522 6.894 11.943 18.223 18.177 26.527 21.294 9.077 3.412 16.63 3.981 16.721 3.981a1.537 1.537 0 011.433 1.661c-.045.819-.728 1.433-1.547 1.433l-.022.023z"
        fill="url(#paint1_linear_5935_30191)"
      />
      <Path
        d="M51.892 44.818c3.003.409.136 17.63-3.435 16.084-3.572-1.547-.41-16.608 3.435-16.084zM58.217 62.335c2.707 1.911-7.69 14.037-10.17 11.717-2.48-2.321 7.417-13.65 10.17-11.717z"
        fill="#003349"
      />
      <Path
        d="M37.81 62.335c-2.707 1.911 7.69 14.037 10.17 11.717 2.479-2.321-7.417-13.65-10.17-11.717zM59.172 74.802c2.708 1.911-7.69 14.037-10.169 11.716-2.48-2.32 7.416-13.65 10.17-11.716z"
        fill="#003349"
      />
      <Path
        d="M38.766 74.802c-2.708 1.911 7.689 14.037 10.169 11.716 2.48-2.32-7.417-13.65-10.17-11.716zM60.537 87.314c2.958 1.547-5.55 14.879-8.349 12.9-2.798-1.98 5.346-14.47 8.35-12.9z"
        fill="#003349"
      />
      <Path
        d="M40.335 89.954c-2.411 2.23 9.67 12.899 11.785 10.283 2.116-2.616-9.35-12.558-11.785-10.283zM63.836 100.236c3.14 1.183-3.526 15.425-6.552 13.787s3.367-14.97 6.552-13.787z"
        fill="#003349"
      />
      <Path
        d="M44.134 105.196c-2.093 2.502 11.307 11.671 13.059 8.827 1.752-2.844-10.943-11.352-13.059-8.827zM68.09 110.701c3.322.637-.546 15.766-3.822 14.651-3.298-1.092.455-15.31 3.822-14.651z"
        fill="#003349"
      />
      <Path
        d="M49.662 118.914c-1.57 2.821 13.355 9.578 14.538 6.507 1.183-3.094-12.922-9.351-14.538-6.507zM73.664 119.256c3.39-.16 3.708 15.379.227 15.083-3.48-.296-3.663-14.924-.227-15.083z"
        fill="#003349"
      />
      <Path
        d="M58.035 131.518c-.774 3.094 15.492 6.142 15.81 2.866.32-3.253-15.014-6.006-15.81-2.866z"
        fill="#003349"
      />
      <Path
        d="M77.782 147.192a1.56 1.56 0 01-1.547-1.433c-.068-.864.591-1.592 1.433-1.661.068 0 7.621-.568 16.699-3.981 8.303-3.117 19.656-9.35 26.526-21.294 9.396-16.289 11.876-33.215 12.308-44.522.455-12.307-1.342-20.884-1.342-20.952a1.558 1.558 0 011.183-1.843 1.54 1.54 0 011.842 1.183c.069.364 1.889 8.94 1.434 21.635-.433 11.694-2.981 29.188-12.718 46.069-5.824 10.101-15.583 17.95-28.21 22.682-9.486 3.549-17.176 4.117-17.494 4.14h-.114v-.023z"
        fill="url(#paint2_linear_5935_30191)"
      />
      <Path
        d="M130.994 44.818c-3.003.409-.136 17.63 3.435 16.084 3.572-1.547.41-16.608-3.435-16.084zM124.67 62.335c-2.707 1.911 7.689 14.037 10.169 11.717 2.48-2.321-7.416-13.65-10.169-11.717z"
        fill="#003349"
      />
      <Path
        d="M145.077 62.335c2.707 1.911-7.69 14.037-10.17 11.717-2.479-2.321 7.417-13.65 10.17-11.717zM123.714 74.802c-2.707 1.911 7.69 14.037 10.169 11.716 2.48-2.32-7.416-13.65-10.169-11.716z"
        fill="#003349"
      />
      <Path
        d="M144.098 74.802c2.707 1.911-7.69 14.037-10.169 11.716-2.48-2.32 7.416-13.65 10.169-11.716zM122.326 87.314c-2.957 1.547 5.551 14.879 8.349 12.9 2.799-1.98-5.346-14.47-8.349-12.9z"
        fill="#003349"
      />
      <Path
        d="M142.551 89.954c2.411 2.23-9.669 12.899-11.785 10.283-2.115-2.616 9.351-12.558 11.785-10.283zM119.027 100.236c-3.139 1.183 3.527 15.425 6.552 13.787 3.026-1.638-3.367-14.97-6.552-13.787z"
        fill="#003349"
      />
      <Path
        d="M138.729 105.196c2.093 2.502-11.306 11.671-13.058 8.827-1.752-2.844 10.942-11.352 13.058-8.827zM114.796 110.701c-3.321.637.546 15.766 3.822 14.651 3.299-1.092-.455-15.31-3.822-14.651z"
        fill="#003349"
      />
      <Path
        d="M133.224 118.914c1.57 2.821-13.354 9.578-14.537 6.507-1.183-3.094 12.922-9.351 14.537-6.507zM109.2 119.256c-3.39-.16-3.709 15.379-.228 15.083 3.481-.296 3.663-14.924.228-15.083z"
        fill="#003349"
      />
      <Path
        d="M124.852 131.518c.773 3.094-15.493 6.142-15.811 2.866-.319-3.253 15.015-6.006 15.811-2.866z"
        fill="#003349"
      />
      <Path
        d="M95.39 100.782h-7.166l.25-2.547 1.228-11.808.296-2.957h3.617l1.775 17.312z"
        fill="#0052BF"
      />
      <Path
        d="M92.842 98.12l-4.368.115 1.229-11.808 2.252-.204.887 11.898z"
        fill="#003349"
      />
      <Path
        d="M96.869 98.621v1.388c0 .387-.319.728-.705.728H87.45a.714.714 0 01-.705-.728v-1.388c0-.386.319-.705.705-.705h8.714c.386 0 .705.319.705.705z"
        fill="#66A7FF"
      />
      <Path
        d="M96.869 98.621v1.388c0 .387-.319.728-.705.728H87.45a.714.714 0 01-.705-.728v-.432c2.002.045 7.28.182 7.667 0 .387-.205 0-1.251-.182-1.661h1.91c.388 0 .706.319.706.705h.023z"
        fill="#003349"
      />
      <Path
        d="M109.586 110.724H74.028l.227-1.865 1.07-8.145h32.942l1.069 8.145.25 1.865z"
        fill="url(#paint3_linear_5935_30191)"
      />
      <Path
        d="M109.586 110.724H74.028l.227-1.865h35.081l.25 1.865z"
        fill="#0052BF"
      />
      <Path
        d="M110.109 110.724H73.505c-.774 0-1.41.637-1.41 1.411 0 .773.636 1.41 1.41 1.41h36.604c.774 0 1.411-.637 1.411-1.41 0-.774-.637-1.411-1.411-1.411z"
        fill="#003349"
      />
      <Path
        d="M112.817 66.475l-9.032 8.805 2.138 12.444c.296 1.683-1.478 2.957-2.98 2.161l-11.17-5.87-11.17 5.87c-.797.41-1.661.25-2.253-.227-.546-.433-.864-1.138-.728-1.957l2.139-12.444-9.032-8.804c-1.228-1.183-.546-3.276 1.138-3.504l12.49-1.82 5.573-11.307c.41-.841 1.274-1.228 2.093-1.137a1.99 1.99 0 011.593 1.137l5.573 11.307 12.49 1.82c1.684.25 2.366 2.32 1.138 3.504v.022z"
        fill="url(#paint4_linear_5935_30191)"
      />
      <Path
        d="M91.045 54.6c1.57 0-1.57 9.532-3.503 9.055-1.934-.478 2.16-9.055 3.503-9.055zM78.487 65.088c-.478 1.046 6.28 1.57 6.393.728.114-.842-5.779-2.048-6.393-.728z"
        fill="#F3F3F3"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_5935_30191"
          x1={5.0498}
          y1={90.9998}
          x2={177.95}
          y2={90.9998}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_5935_30191"
          x1={46.4776}
          y1={99.3266}
          x2={106.629}
          y2={99.3266}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#A5CF4F" />
          <Stop offset={1} stopColor="#003349" />
        </LinearGradient>
        <LinearGradient
          id="paint2_linear_5935_30191"
          x1={76.2348}
          y1={99.3263}
          x2={136.409}
          y2={99.3263}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint3_linear_5935_30191"
          x1={74.0278}
          y1={105.719}
          x2={109.586}
          y2={105.719}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
        <LinearGradient
          id="paint4_linear_5935_30191"
          x1={70.0694}
          y1={69.4101}
          x2={111.611}
          y2={69.4101}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#003349" />
          <Stop offset={1} stopColor="#A5CF4F" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export function linkExistingAccountStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    title: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f2,
    },
    container: {
      flex: 1,
      padding: Styles.Spacing.m3,
    },
  };
}

export function actionLinkText(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    actionLinkText: {
      color: '#003349',
      fontSize: 16,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    actionlinkView: {
      alignSelf: 'flex-start',
      borderBottomColor: Styles.primaryColor,
      borderBottomWidth: 1.5,
    },
  };
}

export function getBodyTextStyles(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    bodyText: {
      color: Styles.Colors.PayNew.black01,
      fontSize: 18,
      fontWeight: '400',
      marginBottom: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m2,
      paddingStart: Styles.Spacing.m3 + Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontStyle: 'normal',
    },
    supportingText: {
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: '400',
      color: Styles.Colors.PayNew.darkHuesBase03,
      marginBottom: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m2,
      paddingStart: Styles.Spacing.m3 + Styles.Spacing.m2,
    },
    forgotPin: {
      color: Styles.Colors.PayNew.black01,
      fontSize: 16,
      fontWeight: '400',
      marginBottom: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m2,
      paddingStart: Styles.Spacing.m3 + Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.aeonikRegular,
      marginRight: Styles.Spacing.m1,
    },
    forgotPinDescription: {
      color: Styles.Colors.PayNew.black01,
      fontSize: 16,
      fontWeight: '400',
      marginBottom: Styles.Spacing.m2,
      marginTop: -Styles.Spacing.m2,
      paddingStart: Styles.Spacing.m3 + Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.aeonikRegular,
      marginRight: Styles.Spacing.m1,
      fontStyle: 'italic',
    },
  };
}

export function getWelcomeStatusBarStyleDescriptor(): string {
  return Styles.loginBackground;
}

export function getWelcomeViewBckGrndStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    view: {
      flex: 1,
    },
    overlay: {},
  };
}

export function baseAccountContainerDescriptor(): {
  [key: string]: {[key: string]: number | string};
} {
  return {
    container: {
      paddingBottom: Platform.OS === 'android' ? 10 : 0,
      backgroundColor: Styles.white,
    },
    headerText: {
      marginTop: Styles.Spacing.m3,
      fontSize: Styles.Fonts.f2,
      fontWeight: 'bold',
      color: Styles.darkColor,
    },
  };
}
export function getOneTimeTitle(): string {
  return Localized.Labels.one_time_rewards_title;
}

export function getOneTimePayEarnColor(): string {
  return '#003349';
}

export function getWelcomeOverLayImage() {
  return null;
}

export function getFocusAwareStatusBar() {
  return null;
}

export function getOrTextDescriptor(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    orText: {
      fontSize: Styles.Fonts.f1,
      textTransform: 'uppercase',
    },
    solidLine: {
      backgroundColor: '#999',
    },
  };
}

export function getLineContainer(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    lineContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical: Styles.Spacing.m1,
    },
  };
}

export function getVersionTextDescriptor(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    versionText: {
      color: Styles.Colors.Canteen.darkGreen,
      marginLeft: Styles.Spacing.m3,
    },
  };
}
export function getLogoContainer(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    logoContainer: {
      alignSelf: 'center',
      height: Styles.Heights.h3,
      justifyContent: 'center',
      paddingTop: Styles.Spacing.m1,
      marginTop: -80,
      marginBottom: -20,
      flex: 1,
    },
  };
}

export function onboardingLinklocationSuccessTextColor(): string {
  return Styles.Colors.Canteen.darkGreen;
}

export function getWelcomeButtonStyles(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    topButton: {
      marginTop: Styles.Spacing.m3,
      alignSelf: 'auto',
      paddingVertical: Styles.Spacing.m1,
    },
  };
}

export function getContainer(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    container: {
      marginTop: 120,
    },
  };
}

export function locationCodebgColor(): string {
  return '#066DFF';
}

export function getStatusSystem(): string {
  return Localized.Labels.connect_status_system;
}
export function getOnBoardingButton(
  buttonText: string,
  handleClick: () => void,
  disabled?: boolean,
): JSX.Element {
  return (
    <RoundedAppButton
      buttonViewStyle={{height: 43, width: 312}}
      textStyle={[
        {
          fontSize: Styles.Fonts.f1 + 2,
          fontWeight: '700',
        },
        getSnackDescriber().snackDetailsBtnFontSTyle(),
      ]}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
      isDisabled={disabled}
      inActiveButtonViewStyle={{
        backgroundColor: Styles.Colors.PayNew.darkHuesBase04,
        height: 43,
        width: 312,
      }}
      inActiveTextStyle={{fontSize: Styles.Fonts.f1 + 2}}
      buttonType={RoundedAppButtonType.Solid}
      titleText={buttonText}
      accessibilityLabelValue={buttonText}
      aria-label={buttonText}
      onPress={handleClick}
    />
  );
}

export function getLocationCodeText(
  state,
  maxCharacters,
  doSetState,
  locationNote,
) {
  return (
    <AllyTextInput
      accessible={true}
      accessibilityLabel={Localized.Labels.location_code}
      accessibilityHint={Localized.Labels.please_enter_location_code}
      accessibilityValue={{text: state.locationCode}}
      label={Localized.Labels.location_code}
      value={state.locationCode}
      onChangeText={doSetState}
      autoCapitalize="none"
      showHelperText={true}
      helperText={locationNote}
      maxLength={maxCharacters}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
      helperStyle={{
        fontFamily: Styles.FontFamily.robotoRegular,
        fontSize: 14,
        fontWeight: '400',
      }}
    />
  );
}

export function getGoToSettings() {
  return Localized.Buttons.go_to_settings;
}

export function seeMoreDetailtxt() {
  return Localized.Buttons.see_more_details;
}
export function getManualLinktxt() {
  return Localized.Buttons.manually_link_new_location;
}

export default {
  linkExistingAccountStyleDescriptor,
  getWelcomeStatusBarStyleDescriptor,
  getWelcomeViewBckGrndStyleDescriptor,
  baseAccountContainerDescriptor,
  getManualLinktxt,
  getOrTextDescriptor,
  seeMoreDetailtxt,
  getVersionTextDescriptor,
  getGoToSettings,
  renderPointsToNextRewardLbl,
  getLogoContainer,
  getLetsGoStyle,
  getTrailBlazerIcon,
  getContainer,
  getWelcomeOverLayImage,
  getFocusAwareStatusBar,
  getLineContainer,
  getEliteBuyerTier,
  getWelcomeButtonStyles,
  renderYourBalanceRewardLbl,
  locationCodebgColor,
  getOnBoardingButton,
  getRedeemColor,
  actionLinkText,
  getRewardsIcon,
  getOneTimePayEarnColor,
  getBodyTextStyles,
  getStatusSystem,
  onboardingLinklocationSuccessTextColor,
  getMemberTier,
  getOneTimeTitle,
  getLocationCodeText,
  getSmartShopper,
};
