import {getDescriber as getSnackDescriber} from 'src/components/screens/sendSnack/descriptor/sendasnack/DescriptorType';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';

import Styles from '../../Styles';
import {Platform} from 'react-native';
import React from 'react';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import Localized from 'src/constants/AppStrings';
import Svg, {Path, Defs, LinearGradient, Stop} from 'react-native-svg';
import AVText from 'src/components/elements/AVText';
import AVFormattedCurrency from 'src/components/elements/AVFormattedCurrency';
export function seeMoreDetailtxt() {
  return Localized.Buttons.see_more_details;
}
export function getTrailBlazerIcon(size, props) {
  return (
    <Svg
      width={size ?? 126}
      height={size ?? 126}
      viewBox="0 0 126 126"
      fill="none"
      {...props}
    >
      <Path
        fill="#84BD00"
        d="M63 122.85c33.054 0 59.85-26.796 59.85-59.85S96.054 3.15 63 3.15 3.15 29.946 3.15 63 29.946 122.85 63 122.85Z"
      />
      <Path
        fill="#E6ECFF"
        d="M63 113.4c28.096 0 50.873-22.777 50.873-50.873 0-28.096-22.777-50.872-50.873-50.872-28.096 0-50.873 22.776-50.873 50.872S34.904 113.4 63 113.4Z"
      />
      <Path
        fill="#009CDE"
        d="M85.144 84.026h-44.73a3.67 3.67 0 0 0-3.67 3.67v3.749a3.67 3.67 0 0 0 3.67 3.67h44.73a3.67 3.67 0 0 0 3.67-3.67v-3.749a3.67 3.67 0 0 0-3.67-3.67Z"
      />
      <Path
        fill="#60A09C"
        d="M85.129 92.988h-44.73a3.684 3.684 0 0 1-3.67-3.67v2.127a3.684 3.684 0 0 0 3.67 3.67h44.73a3.684 3.684 0 0 0 3.67-3.67v-2.127a3.684 3.684 0 0 1-3.67 3.67Z"
        opacity={0.4}
      />
      <Path
        fill="#0052BF"
        d="M55.519 82.845h-2.93c-.157 0-.283.11-.283.252v14.79c0 .424 1.15 1.858 1.15 1.858.267.409.929.409 1.197 0 0 0 1.15-1.434 1.15-1.859V83.097c0-.142-.127-.252-.284-.252Z"
      />
      <Path
        fill="#E7E7E7"
        d="M54.07 92.374h-.047c-.064 0-.11-.063-.11-.142v-1.56c0-.046.078-.204.078-.204.016-.047.063-.047.095 0 0 0 .078.158.078.205v1.559c0 .079-.047.142-.11.142h.016Z"
      />
      <Path
        fill="#E7E7E7"
        d="M56.007 90.925a.208.208 0 0 0-.205-.205h-3.528a.208.208 0 0 0-.205.205v3.827c0 .11.095.205.205.205.11 0 .205-.095.205-.205v-3.623h3.071v3.623c0 .11.095.205.205.205.11 0 .205-.095.205-.205l.047-3.843v.016Z"
      />
      <Path
        fill="#E7E7E7"
        d="M55.881 94.8h-3.67a.535.535 0 0 0-.535.535v.315c0 .296.24.535.535.535h3.67c.296 0 .535-.24.535-.535v-.315a.535.535 0 0 0-.535-.536ZM55.881 88.279h-3.67a.535.535 0 0 0-.535.535v.315c0 .296.24.536.535.536h3.67c.296 0 .535-.24.535-.536v-.315a.535.535 0 0 0-.535-.535Z"
      />
      <Path
        fill="#0052BF"
        d="M68.749 83.27h2.93c.157 0 .283.11.283.237v14.426c0 .426-1.15 1.812-1.15 1.812-.268.394-.93.394-1.197 0 0 0-1.15-1.386-1.15-1.812V83.506c0-.126.126-.236.284-.236Z"
      />
      <Path
        fill="#E7E7E7"
        d="M70.198 92.374h.047c.063 0 .11-.063.11-.142v-1.56a.928.928 0 0 0-.079-.204c-.015-.047-.063-.047-.094 0 0 0-.079.158-.079.205v1.559c0 .079.047.142.11.142h-.015Z"
      />
      <Path
        fill="#E7E7E7"
        d="M68.245 90.925c0-.11.094-.205.204-.205h3.529c.11 0 .204.094.204.205v3.827c0 .11-.094.205-.204.205a.208.208 0 0 1-.205-.205v-3.623H68.7v3.623c0 .11-.094.205-.204.205a.208.208 0 0 1-.205-.205l-.047-3.843v.016Z"
      />
      <Path
        fill="#E7E7E7"
        d="M68.37 96.185h3.67c.296 0 .536-.24.536-.535v-.315a.536.536 0 0 0-.535-.536h-3.67a.536.536 0 0 0-.536.536v.315c0 .296.24.535.536.535ZM68.37 89.79h3.67c.296 0 .536-.239.536-.535v-.315a.535.535 0 0 0-.535-.535h-3.67a.535.535 0 0 0-.536.535v.315c0 .296.24.536.536.536Z"
      />
      <Path
        fill="#003780"
        d="M62.386 25.846c-2.347 0-4.269 3.449-4.269 7.717h.946c0-3.764 1.48-6.835 3.307-6.835s3.307 3.055 3.307 6.835h.945c0-4.268-1.905-7.717-4.252-7.717h.016Z"
      />
      <Path
        fill="#003780"
        d="m54.453 23.82-2.29.516a2.158 2.158 0 0 0-1.63 2.579l1.925 8.543a2.158 2.158 0 0 0 2.58 1.63l2.289-.516a2.158 2.158 0 0 0 1.63-2.579l-1.925-8.543a2.158 2.158 0 0 0-2.58-1.63ZM67.388 36.565l2.29.516a2.158 2.158 0 0 0 2.58-1.63l1.924-8.544a2.158 2.158 0 0 0-1.63-2.579l-2.29-.516a2.158 2.158 0 0 0-2.579 1.63l-1.925 8.544a2.158 2.158 0 0 0 1.63 2.579Z"
      />
      <Path
        fill="#009CDE"
        d="M81.38 81.427h-2.362a3.757 3.757 0 0 1-3.749-3.748V66.087c0-.803.646-1.449 1.45-1.449h6.977c.803 0 1.448.646 1.448 1.449v11.592a3.757 3.757 0 0 1-3.748 3.748h-.016Z"
      />
      <Path
        fill="#0052BF"
        d="M79.144 62.906h3.15a3.347 3.347 0 0 1 3.355 3.354v1.465c0 .709-.583 1.291-1.292 1.291h-7.276a1.297 1.297 0 0 1-1.292-1.291V66.26a3.347 3.347 0 0 1 3.355-3.355Z"
      />
      <Path
        fill="#009CDE"
        d="M41.202 64.59h6.977c.803 0 1.45.647 1.45 1.45v11.592a3.757 3.757 0 0 1-3.75 3.748h-2.362a3.757 3.757 0 0 1-3.748-3.748V66.04c0-.803.645-1.45 1.449-1.45h-.016Z"
      />
      <Path
        fill="#0052BF"
        d="M47.817 68.97h-7.276a1.297 1.297 0 0 1-1.292-1.292v-1.465a3.348 3.348 0 0 1 3.355-3.355h3.15a3.347 3.347 0 0 1 3.355 3.355v1.465c0 .709-.583 1.291-1.292 1.291Z"
      />
      <Path
        fill="#006DFF"
        d="M58.102 35.012h8.552c7.481 0 13.545 6.064 13.545 13.545v30.886a4.786 4.786 0 0 1-4.788 4.788H49.345a4.786 4.786 0 0 1-4.788-4.788V48.557c0-7.481 6.063-13.545 13.545-13.545Z"
      />
      <Path
        fill="#F8A326"
        d="M75.411 81.837H49.345c-2.63 0-4.788-2.158-4.788-4.788v2.378c0 2.63 2.157 4.788 4.788 4.788H75.41c2.63 0 4.788-2.157 4.788-4.788V77.05c0 2.63-2.158 4.788-4.788 4.788Z"
        opacity={0.4}
      />
      <Path
        fill="#009CDE"
        d="M49.329 31.626h26.13c1.212 0 2.188.976 2.188 2.19v6.976a8.268 8.268 0 0 1-8.268 8.27h-13.97a8.268 8.268 0 0 1-8.27-8.27v-6.977c0-1.212.977-2.189 2.19-2.189Z"
      />
      <Path
        fill="#BE4D27"
        d="M75.442 31.626H49.313c-1.197 0-2.189.992-2.189 2.19v1.054c0-1.197.992-2.189 2.19-2.189h26.128c1.198 0 2.19.992 2.19 2.19v-1.056c0-1.197-.993-2.189-2.19-2.189Z"
        opacity={0.3}
      />
      <Path
        fill="#009CDE"
        d="M49.329 63.41h26.13c1.212 0 2.188.976 2.188 2.189v10.41a8.268 8.268 0 0 1-8.268 8.27h-13.97a8.268 8.268 0 0 1-8.27-8.27V65.6c0-1.213.977-2.19 2.19-2.19Z"
      />
      <Path
        fill="#009CDE"
        d="M49.093 63.41h26.586a1.97 1.97 0 0 1 1.968 1.968v2.961a7.404 7.404 0 0 1-7.402 7.403H54.558a7.404 7.404 0 0 1-7.402-7.403v-2.96a1.97 1.97 0 0 1 1.968-1.97h-.031ZM69.473 52.007H55.298a2.158 2.158 0 0 0-2.157 2.157v3.938c0 1.192.966 2.158 2.157 2.158h14.175a2.158 2.158 0 0 0 2.158-2.158v-3.938a2.158 2.158 0 0 0-2.158-2.157Z"
      />
      <Path
        fill="#0052BF"
        d="M63.913 40.084h-3.07c-.158 0-.3.126-.3.267v16.444c0 .488 1.197 2.063 1.197 2.063.283.457.977.457 1.244 0 0 0 1.197-1.591 1.197-2.063V40.35c0-.157-.126-.267-.299-.267h.032Z"
      />
      <Path
        fill="#E7E7E7"
        d="M62.401 48.825h-.047c-.063 0-.11-.063-.11-.158v-1.622c0-.047.095-.22.095-.22a.05.05 0 0 1 .094 0s.095.173.095.22v1.622c0 .08-.048.158-.11.158H62.4Z"
      />
      <Path
        fill="#E7E7E7"
        d="M64.433 47.297c0-.11-.094-.22-.22-.22h-3.67c-.11 0-.22.11-.22.22v4c0 .11.094.221.22.221s.22-.094.22-.22v-3.78h3.198v3.78c0 .11.094.22.22.22s.22-.094.22-.22l.048-4h-.016Z"
      />
      <Path
        fill="#E7E7E7"
        d="M64.323 51.345h-3.859a.551.551 0 0 0-.551.551v.347c0 .304.247.551.551.551h3.859a.551.551 0 0 0 .551-.551v-.347a.551.551 0 0 0-.551-.551ZM64.323 43.328h-3.859a.551.551 0 0 0-.551.551v.347c0 .304.247.551.551.551h3.859a.551.551 0 0 0 .551-.551v-.347a.551.551 0 0 0-.551-.55ZM62.449 54.369a.315.315 0 1 0 0-.63.315.315 0 0 0 0 .63ZM62.449 55.786a.315.315 0 1 0 0-.63.315.315 0 0 0 0 .63ZM62.449 57.188a.315.315 0 1 0 0-.63.315.315 0 0 0 0 .63Z"
      />
      <Path
        fill="#0052BF"
        d="M55.818 62.937h-2.93c-.157 0-.283.126-.283.3v17.797c0 .52 1.15 2.236 1.15 2.236a.672.672 0 0 0 1.197 0s1.15-1.716 1.15-2.236V63.236c0-.157-.127-.299-.284-.299Z"
      />
      <Path
        fill="#E7E7E7"
        d="M54.369 75.978h-.047c-.063 0-.11-.063-.11-.142v-1.559c0-.047.078-.205.078-.205.016-.047.063-.047.095 0 0 0 .079.158.079.205v1.56c0 .078-.048.141-.11.141h.015Z"
      />
      <Path
        fill="#E7E7E7"
        d="M56.322 74.529a.209.209 0 0 0-.205-.205H52.59a.208.208 0 0 0-.204.205v3.827c0 .11.094.205.204.205.11 0 .205-.095.205-.205v-3.622h3.071v3.622c0 .11.095.205.205.205.11 0 .205-.095.205-.205l.047-3.843v.016Z"
      />
      <Path
        fill="#E7E7E7"
        d="M56.18 78.388h-3.67a.535.535 0 0 0-.535.535v.315c0 .296.24.536.535.536h3.67c.296 0 .536-.24.536-.536v-.315a.535.535 0 0 0-.536-.535ZM56.18 70.718h-3.67a.535.535 0 0 0-.535.535v.315c0 .296.24.535.535.535h3.67c.296 0 .536-.24.536-.535v-.315a.535.535 0 0 0-.536-.535ZM56.18 67.252h-3.67a.535.535 0 0 0-.535.536v.315c0 .296.24.535.535.535h3.67c.296 0 .536-.24.536-.535v-.315a.535.535 0 0 0-.536-.536Z"
      />
      <Path
        fill="#0052BF"
        d="M69.048 62.937h2.93c.157 0 .283.126.283.3v17.797c0 .52-1.15 2.236-1.15 2.236a.672.672 0 0 1-1.197 0s-1.15-1.716-1.15-2.236V63.236c0-.157.127-.299.284-.299Z"
      />
      <Path
        fill="#E7E7E7"
        d="M70.497 75.978h.047c.063 0 .11-.063.11-.142v-1.559c0-.047-.078-.205-.078-.205-.016-.047-.063-.047-.095 0 0 0-.078.158-.078.205v1.56c0 .078.047.141.11.141h-.016Z"
      />
      <Path
        fill="#E7E7E7"
        d="M68.56 74.529c0-.11.094-.205.204-.205h3.529c.11 0 .204.095.204.205v3.827c0 .11-.094.205-.204.205a.208.208 0 0 1-.205-.205v-3.622h-3.072v3.622c0 .11-.094.205-.204.205a.209.209 0 0 1-.205-.205l-.047-3.843v.016Z"
      />
      <Path
        fill="#E7E7E7"
        d="M68.686 79.79h3.67c.295 0 .535-.24.535-.536v-.315a.536.536 0 0 0-.535-.535h-3.67a.536.536 0 0 0-.536.535v.315c0 .296.24.536.536.536ZM68.686 72.103h3.67c.295 0 .535-.24.535-.535v-.315a.536.536 0 0 0-.535-.535h-3.67a.536.536 0 0 0-.536.535v.315c0 .296.24.535.536.535ZM68.686 68.906h3.67c.295 0 .535-.24.535-.535v-.315a.536.536 0 0 0-.535-.536h-3.67a.536.536 0 0 0-.536.536v.315c0 .296.24.535.536.535Z"
      />
    </Svg>
  );
}
export function getRedeemColor() {
  return {
    backgroundColor: '#003349',
    borderTopColor: '#003349',
    borderBottomColor: '#003349',
    borderLeftColor: '#003349',
    borderRightColor: '#003349',
    borderColor: '#003349',
  };
}
export function renderPointsToNextRewardLbl(_styles, nextPoint) {
  const morePointsThanHigher = nextPoint < 0 ? true : false;
  return (
    <>
      <AVText
        style={_styles.pointsToNextRwd}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
        testID={Localized.Labels.redeem}
        adjustsFontSizeToFit={true}
      >
        {!morePointsThanHigher
          ? Localized.Labels.formatString(
              Localized.Labels.points_next_rewrd,
              nextPoint,
            )
          : Localized.Labels.more_points_than_available}
      </AVText>
    </>
  );
}
export function getSmartShopper(size, props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 126}
      height={size ?? 126}
      viewBox="0 0 126 126"
      fill="none"
      {...props}
    >
      <Path
        fill="#84BD00"
        d="M63 122.85c33.054 0 59.85-26.796 59.85-59.85S96.054 3.15 63 3.15 3.15 29.946 3.15 63 29.946 122.85 63 122.85Z"
      />
      <Path
        fill="#E6ECFF"
        d="M63 113.872c28.096 0 50.873-22.776 50.873-50.872S91.096 12.127 63 12.127c-28.096 0-50.873 22.777-50.873 50.873 0 28.096 22.777 50.872 50.873 50.872Z"
      />
      <Path
        fill="#009CDE"
        d="M87.066 51.77h.866c0-6.883-2.788-13.12-7.308-17.624A24.854 24.854 0 0 0 63 26.854c-.441 0-.882 0-1.339.031-6.442.347-12.269 3.213-16.506 7.592-4.236 4.378-6.914 10.3-7.056 16.758v.535c0 4.142 1.008 8.064 2.804 11.498a25.031 25.031 0 0 0 7.497 8.678c.535.394.96.882 1.26 1.449.299.551.457 1.166.457 1.811v.536c0 .929.346 1.78.945 2.394.299.299.645.551 1.039.724a3.18 3.18 0 0 0 1.276.252h19.246c.898 0 1.717-.362 2.316-.96.598-.583.96-1.418.96-2.316v-.645c0-.646.158-1.26.457-1.812.3-.551.725-1.04 1.26-1.433a25.21 25.21 0 0 0 7.513-8.663 24.792 24.792 0 0 0 2.819-11.513v-2.095l-1.496 1.497.614.614.614.614h.016l-.614-.63h-.866c0 3.875-.945 7.513-2.63 10.71a23.157 23.157 0 0 1-6.994 8.064 6.012 6.012 0 0 0-1.764 2.016 5.507 5.507 0 0 0-.661 2.615v.645c0 .426-.174.82-.457 1.087a1.524 1.524 0 0 1-1.087.457H53.377a1.45 1.45 0 0 1-.599-.126 1.567 1.567 0 0 1-.677-.567c-.173-.252-.268-.567-.268-.945v-.535c0-.93-.236-1.828-.661-2.63a6.088 6.088 0 0 0-1.78-2.033 23.164 23.164 0 0 1-6.977-8.08A23.04 23.04 0 0 1 39.8 51.755v-.503c.126-5.985 2.615-11.498 6.568-15.577 3.953-4.095 9.371-6.757 15.356-7.072.426-.016.835-.032 1.245-.032 6.41 0 12.206 2.6 16.411 6.789a23.077 23.077 0 0 1 6.788 16.395v2.095l1.48-1.48-.614-.614.032.015ZM53.392 88.5v.866h19.231c.425 0 .803.173 1.087.456a1.524 1.524 0 0 1 0 2.174 1.524 1.524 0 0 1-1.087.457h-19.23c-.426 0-.804-.174-1.087-.457a1.524 1.524 0 0 1 0-2.174 1.524 1.524 0 0 1 1.087-.456v-1.733c-.898 0-1.733.362-2.316.96a3.235 3.235 0 0 0-.96 2.316c0 .898.362 1.717.96 2.315a3.3 3.3 0 0 0 2.316.961h19.23c.898 0 1.733-.362 2.316-.96.598-.583.96-1.418.96-2.316 0-.898-.362-1.717-.96-2.315a3.3 3.3 0 0 0-2.316-.96h-19.23v.865Z"
      />
      <Path
        fill="#009CDE"
        d="M67.82 78.23h-2.41V57.787h-4.804V78.23h-2.41V57.787h-3.008a5.428 5.428 0 0 1-5.418-5.418 5.418 5.418 0 1 1 10.836 0v3.008h4.804v-3.008a5.428 5.428 0 0 1 5.418-5.418 5.418 5.418 0 1 1 0 10.836H67.82V78.23Zm0-22.853h3.008a3.018 3.018 0 0 0 3.008-3.008 3.018 3.018 0 0 0-3.008-3.009 3.018 3.018 0 0 0-3.008 3.009v3.008Zm-12.632-6a3.018 3.018 0 0 0-3.008 3.007 3.018 3.018 0 0 0 3.008 3.009h3.008v-3.009a3.018 3.018 0 0 0-3.008-3.008ZM53.392 83.38v.867h19.231c.425 0 .803.173 1.087.456a1.524 1.524 0 0 1 0 2.174 1.524 1.524 0 0 1-1.087.457h-19.23c-.426 0-.804-.174-1.087-.457a1.524 1.524 0 0 1 0-2.174 1.524 1.524 0 0 1 1.087-.456v-1.733c-.898 0-1.733.362-2.316.96a3.235 3.235 0 0 0-.96 2.316c0 .898.362 1.717.96 2.315a3.3 3.3 0 0 0 2.316.961h19.23c.898 0 1.733-.362 2.316-.96.598-.583.96-1.418.96-2.316 0-.898-.362-1.717-.96-2.315a3.3 3.3 0 0 0-2.316-.96h-19.23v.865Z"
      />
      <Path
        fill="#009CDE"
        d="M53.392 78.246v.866h19.231c.425 0 .803.173 1.087.457a1.524 1.524 0 0 1 0 2.173 1.524 1.524 0 0 1-1.087.457h-19.23c-.426 0-.804-.173-1.087-.457a1.524 1.524 0 0 1 0-2.173 1.524 1.524 0 0 1 1.087-.457V77.38c-.898 0-1.733.362-2.316.96a3.235 3.235 0 0 0-.96 2.316c0 .897.362 1.716.96 2.315a3.3 3.3 0 0 0 2.316.96h19.23c.898 0 1.733-.362 2.316-.96.598-.583.96-1.418.96-2.315 0-.898-.362-1.717-.96-2.316a3.3 3.3 0 0 0-2.316-.96h-19.23v.866ZM55.786 94.106h14.427a4.803 4.803 0 0 1-4.803 4.804h-4.804a4.803 4.803 0 0 1-4.804-4.804h-.016Z"
      />
      <Path
        fill="#fff"
        d="m81.365 40.304-2.032 1.276a18.487 18.487 0 0 1 1.685 3.402l2.252-.85a22.154 22.154 0 0 0-1.89-3.828h-.015ZM69.52 33.658a19.34 19.34 0 0 1 7.072 4.488l1.701-1.7a21.764 21.764 0 0 0-7.97-5.056l-.818 2.268h.016Z"
      />
    </Svg>
  );
}
export function getRewardsIcon(size) {
  return (
    <Svg width={size ?? 68} height={59} viewBox="0 0 62 59" fill="none">
      <Path
        d="M0 29.5C0 13.208 13.208 0 29.5 0h3C48.792 0 62 13.208 62 29.5S48.792 59 32.5 59h-3C13.208 59 0 45.792 0 29.5z"
        fill="#3A96DB"
        fillOpacity={0.16}
      />
      <Path
        d="M29.682 17.928a4.935 4.935 0 00-4.25-2.428h-.12a4.813 4.813 0 00-4.287 7h-1.4A2.626 2.626 0 0017 25.125v3.5c0 1.143.733 2.116 1.75 2.477V40c0 1.93 1.57 3.5 3.5 3.5h17.5c1.93 0 3.5-1.57 3.5-3.5v-8.898A2.632 2.632 0 0045 28.625v-3.5a2.626 2.626 0 00-2.625-2.625h-1.4a4.813 4.813 0 00-4.288-7h-.12a4.935 4.935 0 00-4.249 2.428L31 20.176l-1.318-2.242v-.006zm12.693 7.197v3.5H32.75v-3.5h9.625zm-13.125 0v3.5h-9.625v-3.5h9.625zm0 6.125v9.625h-7a.878.878 0 01-.875-.875v-8.75h7.875zm3.5 9.625V31.25h7.875V40a.878.878 0 01-.875.875h-7zM36.688 22.5h-4.009l1.903-3.238a2.305 2.305 0 011.985-1.137h.12a2.187 2.187 0 110 4.375zm-7.438 0h-3.938a2.187 2.187 0 110-4.375h.12c.816 0 1.576.432 1.986 1.137l1.903 3.238h-.071z"
        fill="#009CDE"
      />
    </Svg>
  );
}
export function getManualLinktxt() {
  return Localized.Buttons.manually_link_new_location;
}
export function renderYourBalanceRewardLbl(_styles, _accountStr) {
  return (
    <>
      <AVText
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
        style={_styles.payBalance}
      >
        {`${Localized.Labels.your_balance}: `}
        <AVFormattedCurrency
          testID={'balanceFormattedCurrency'}
          accessibilityLabel={
            _accountStr.getAccountBalance()
              ? `$${_accountStr.getAccountBalance().toString()}`
              : '0'
          }
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          style={_styles.payBalance}
          value={_accountStr.getAccountBalance() ?? 0}
          currency={_accountStr.getCurrency()}
        />
      </AVText>
    </>
  );
}

export function onboardingLinklocationSuccessTextColor(): string {
  return Styles.Colors.Canteen.darkGreen;
}
export function getLetsGoStyle() {
  return {textTransform: 'capitalize'};
}
export function getMemberTier(size, props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 126}
      height={size ?? 126}
      viewBox="0 0 126 126"
      fill="none"
      {...props}
    >
      <Path
        fill="#EBEFF8"
        d="M63 122.85c33.054 0 59.85-26.796 59.85-59.85S96.054 3.15 63 3.15 3.15 29.946 3.15 63 29.946 122.85 63 122.85Z"
      />
      <Path
        fill="#84BD00"
        fillRule="evenodd"
        d="M122.85 63c0 33.054-26.796 59.85-59.85 59.85S3.15 96.054 3.15 63 29.946 3.15 63 3.15 122.85 29.946 122.85 63Zm-8.82 0c0 28.096-22.776 50.872-50.872 50.872S12.285 91.096 12.285 63s22.777-50.873 50.873-50.873c28.096 0 50.872 22.777 50.872 50.873Z"
        clipRule="evenodd"
      />
      <Path
        fill="#009CDE"
        d="M80.546 74.025a5.018 5.018 0 0 0-.772 2.095c-.457 3.339-3.938 5.355-7.072 4.079a5.142 5.142 0 0 0-6.032 1.622 5.127 5.127 0 0 1-3.591 1.969l-.489.866 10.9 19.073 5.717-10.174 12.41-.19-7.685-13.45-3.37-5.89h-.016ZM58.511 81.805a5.186 5.186 0 0 0-6.032-1.622c-3.118 1.276-6.615-.74-7.072-4.079a5.242 5.242 0 0 0-.771-2.095v.032c-.016 0-3.371 5.859-3.371 5.859l-7.686 13.45 11.907.552 6.206 9.796L62.59 84.625l-.488-.867a5.157 5.157 0 0 1-3.59-1.968v.015Z"
      />
      <Path
        fill="url(#a)"
        d="m62.102 83.79.489.866.488-.866c-.331.031-.662.031-.992 0h.015Z"
      />
      <Path
        fill="url(#b)"
        d="m62.102 83.79.489.866.488-.866c-.331.031-.662.031-.992 0h.015Z"
      />
      <Path
        fill="url(#c)"
        d="m62.59 58.574 6.663 3.811 4.866 2.788-8.615 15.073-2.425 4.252a5.157 5.157 0 0 0 3.59-1.969 5.186 5.186 0 0 1 6.033-1.622c3.118 1.276 6.615-.74 7.072-4.08.11-.77.378-1.48.771-2.094L69.174 54.825 62.59 58.59v-.015Z"
      />
      <Path
        fill="url(#d)"
        d="M52.48 80.891a5.142 5.142 0 0 1 6.031 1.623 5.127 5.127 0 0 0 3.591 1.968l-11.04-19.325 11.529-6.583-6.584-3.765-3.055 5.355-8.316 14.553c.393.615.677 1.323.771 2.095.457 3.34 3.938 5.355 7.072 4.08Z"
      />
      <Path
        fill="url(#e)"
        d="m74.12 64.465-4.867-2.788-6.663-3.812-11.529 6.584 11.041 19.325c.331.032.662.032.992 0l2.426-4.252 8.615-15.073-.016.016Z"
      />
      <Path
        fill="#fff"
        d="m74.12 64.465-4.867-2.788-6.663-3.812-11.529 6.584 11.041 19.325c.331.032.662.032.992 0l2.426-4.252 8.615-15.073-.016.016Z"
      />
      <Path
        fill="#009CDE"
        d="M66.67 24.176a5.187 5.187 0 0 0 6.032 1.623c3.118-1.276 6.615.74 7.072 4.079.315 2.3 2.126 4.11 4.41 4.41 3.339.457 5.355 3.937 4.079 7.072a5.142 5.142 0 0 0 1.622 6.032c2.662 2.063 2.662 6.095 0 8.158a5.186 5.186 0 0 0-1.622 6.033c1.276 3.118-.74 6.615-4.08 7.071-2.299.315-4.11 2.127-4.41 4.41-.456 3.34-3.937 5.355-7.071 4.08a5.142 5.142 0 0 0-6.032 1.622c-2.064 2.661-6.096 2.661-8.159 0a5.186 5.186 0 0 0-6.032-1.623c-3.118 1.276-6.615-.74-7.072-4.079-.315-2.3-2.126-4.11-4.41-4.41-3.339-.456-5.355-3.937-4.079-7.071a5.142 5.142 0 0 0-1.622-6.033c-2.662-2.063-2.662-6.095 0-8.158a5.186 5.186 0 0 0 1.622-6.033c-1.276-3.118.74-6.614 4.08-7.071 2.299-.315 4.11-2.127 4.41-4.41.456-3.34 3.937-5.355 7.071-4.08a5.142 5.142 0 0 0 6.032-1.622c2.063-2.661 6.095-2.661 8.159 0Z"
      />
      <Path
        fill="#fff"
        d="M79.222 51.47c0 8.915-7.229 16.145-16.127 16.145-8.9 0-6.111-.914-8.6-2.473a16.106 16.106 0 0 1-7.544-13.655c0-8.915 7.229-16.128 16.128-16.128 8.899 0 7.465 1.386 10.253 3.67a16.09 16.09 0 0 1 5.875 12.458l.016-.016Z"
      />
      <Path
        fill="#009CDE"
        d="M71.89 60.298c4.867-4.866 4.867-12.756 0-17.623-4.866-4.866-12.756-4.866-17.623 0-4.866 4.867-4.866 12.757 0 17.623 4.867 4.867 12.757 4.867 17.623 0Z"
      />
      <Defs>
        <LinearGradient
          id="a"
          x1={0}
          x2={0.158}
          y1={84.215}
          y2={84.215}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#066DFF" />
          <Stop offset={1} stopColor="#58C6BB" />
        </LinearGradient>
        <LinearGradient
          id="b"
          x1={33.579}
          x2={74.12}
          y1={84.215}
          y2={84.215}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#066DFF" />
          <Stop offset={1} stopColor="#58C6BB" />
        </LinearGradient>
        <LinearGradient
          id="c"
          x1={0}
          x2={0.157}
          y1={69.646}
          y2={69.646}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#066DFF" />
          <Stop offset={1} stopColor="#58C6BB" />
        </LinearGradient>
        <LinearGradient
          id="d"
          x1={33.579}
          x2={74.12}
          y1={69.646}
          y2={69.646}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#066DFF" />
          <Stop offset={1} stopColor="#58C6BB" />
        </LinearGradient>
        <LinearGradient
          id="e"
          x1={0}
          x2={0.158}
          y1={70.844}
          y2={70.844}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#066DFF" />
          <Stop offset={1} stopColor="#58C6BB" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export function getEliteBuyerTier(size, props) {
  return (
    <Svg
      width={size ?? 127}
      height={size ?? 126}
      viewBox="0 0 127 126"
      fill="none"
      {...props}
    >
      <Path
        fill="#84BD00"
        d="M63.5 122.85c33.054 0 59.85-26.796 59.85-59.85S96.554 3.15 63.5 3.15 3.65 29.946 3.65 63s26.796 59.85 59.85 59.85Z"
      />
      <Path
        fill="#E6ECFF"
        d="M63.657 113.872c28.097 0 50.873-22.776 50.873-50.872S91.754 12.127 63.657 12.127c-28.096 0-50.872 22.777-50.872 50.873 0 28.096 22.776 50.872 50.873 50.872Z"
      />
      <Path
        fill="#009CDE"
        d="M72.903 101.902h-.079c-.22 0-5.544-.409-12.112-2.866-8.741-3.276-15.498-8.71-19.53-15.703-6.74-11.686-8.505-23.798-8.804-31.894-.315-8.772.93-14.726.992-14.978a1.067 1.067 0 0 1 1.276-.819c.583.126.945.693.819 1.276 0 .063-1.26 5.985-.93 14.506.3 7.843 2.017 19.545 8.522 30.822 4.772 8.27 12.615 12.585 18.364 14.742 6.284 2.363 11.513 2.757 11.576 2.757.599.047 1.04.551.993 1.149a1.058 1.058 0 0 1-1.072.993l-.015.015Z"
      />
      <Path
        fill="#009CDE"
        d="M36.08 31.027c2.078.284.094 12.207-2.38 11.136-2.472-1.071-.283-11.498 2.38-11.135ZM40.458 43.155c1.874 1.323-5.324 9.718-7.04 8.111-1.717-1.606 5.134-9.45 7.04-8.111Z"
      />
      <Path
        fill="#009CDE"
        d="M26.33 43.155c-1.874 1.323 5.324 9.718 7.04 8.111 1.717-1.606-5.134-9.45-7.04-8.111ZM41.12 51.786c1.873 1.323-5.324 9.718-7.041 8.111-1.717-1.606 5.135-9.45 7.04-8.111Z"
      />
      <Path
        fill="#009CDE"
        d="M26.991 51.786c-1.874 1.323 5.324 9.718 7.04 8.111 1.718-1.606-5.134-9.45-7.04-8.111ZM42.064 60.448c2.048 1.072-3.843 10.301-5.78 8.93-1.937-1.37 3.701-10.016 5.78-8.93Z"
      />
      <Path
        fill="#009CDE"
        d="M28.078 62.276c-1.67 1.543 6.694 8.93 8.159 7.118 1.465-1.81-6.474-8.694-8.159-7.119ZM44.348 69.395c2.174.819-2.441 10.678-4.536 9.544-2.095-1.134 2.331-10.363 4.536-9.544Z"
      />
      <Path
        fill="#009CDE"
        d="M30.709 72.828c-1.45 1.733 7.827 8.08 9.04 6.111 1.213-1.969-7.576-7.86-9.04-6.111ZM47.293 76.64c2.3.44-.378 10.914-2.646 10.142-2.283-.756.315-10.6 2.646-10.143Z"
      />
      <Path
        fill="#009CDE"
        d="M34.536 82.325c-1.087 1.953 9.245 6.631 10.064 4.505.819-2.142-8.946-6.473-10.064-4.505ZM51.152 82.562c2.347-.11 2.567 10.647.157 10.442-2.41-.205-2.535-10.332-.157-10.442Z"
      />
      <Path
        fill="#009CDE"
        d="M40.332 91.05c-.536 2.143 10.725 4.253 10.946 1.985.22-2.252-10.395-4.158-10.946-1.984ZM54.003 101.903a1.08 1.08 0 0 1-1.071-.993 1.075 1.075 0 0 1 .992-1.15c.047 0 5.276-.393 11.56-2.756 5.75-2.157 13.609-6.473 18.365-14.742 6.505-11.277 8.221-22.995 8.52-30.822.316-8.521-.928-14.459-.928-14.506a1.079 1.079 0 0 1 .819-1.276 1.067 1.067 0 0 1 1.275.82c.047.251 1.308 6.189.993 14.977-.3 8.096-2.064 20.208-8.805 31.894-4.032 6.993-10.788 12.427-19.53 15.703-6.567 2.457-11.891 2.851-12.111 2.866h-.08v-.015Z"
      />
      <Path
        fill="#009CDE"
        d="M90.842 31.027c-2.079.284-.094 12.207 2.378 11.136 2.473-1.071.284-11.498-2.378-11.135ZM86.463 43.155c-1.874 1.323 5.324 9.718 7.04 8.111 1.718-1.606-5.134-9.45-7.04-8.111Z"
      />
      <Path
        fill="#009CDE"
        d="M100.591 43.155c1.874 1.323-5.323 9.718-7.04 8.111-1.717-1.606 5.135-9.45 7.04-8.111ZM85.802 51.786c-1.874 1.323 5.323 9.718 7.04 8.111 1.717-1.606-5.134-9.45-7.04-8.111Z"
      />
      <Path
        fill="#009CDE"
        d="M99.914 51.786c1.874 1.323-5.323 9.718-7.04 8.111-1.717-1.606 5.134-9.45 7.04-8.111ZM84.841 60.448c-2.047 1.072 3.843 10.301 5.78 8.93 1.938-1.37-3.7-10.016-5.78-8.93Z"
      />
      <Path
        fill="#009CDE"
        d="M98.843 62.276c1.669 1.543-6.694 8.93-8.159 7.118-1.464-1.81 6.474-8.694 8.159-7.119ZM82.557 69.395c-2.173.819 2.442 10.678 4.536 9.544 2.095-1.134-2.33-10.363-4.536-9.544Z"
      />
      <Path
        fill="#009CDE"
        d="M96.197 72.828c1.449 1.733-7.828 8.08-9.04 6.111-1.213-1.969 7.575-7.86 9.04-6.111ZM79.628 76.64c-2.3.44.378 10.914 2.646 10.142 2.284-.756-.315-10.6-2.646-10.143Z"
      />
      <Path
        fill="#009CDE"
        d="M92.385 82.325c1.087 1.953-9.245 6.631-10.064 4.505-.819-2.142 8.946-6.473 10.064-4.505ZM75.753 82.562c-2.346-.11-2.567 10.647-.157 10.442 2.41-.205 2.536-10.332.157-10.442Z"
      />
      <Path
        fill="#009CDE"
        d="M86.59 91.05c.535 2.143-10.726 4.253-10.947 1.985-.22-2.252 10.395-4.158 10.947-1.984ZM66.193 69.773h-4.961l.173-1.764.85-8.175.206-2.047h2.504l1.228 11.986Z"
      />
      <Path
        fill="#009CDE"
        d="m64.43 67.93-3.025.079.85-8.175 1.56-.141.614 8.237Z"
      />
      <Path
        fill="#fff"
        d="M67.217 68.276v.961c0 .268-.22.504-.488.504h-6.032a.494.494 0 0 1-.489-.504v-.96a.49.49 0 0 1 .489-.489h6.032a.49.49 0 0 1 .488.488Z"
      />
      <Path
        fill="#009CDE"
        d="M67.217 68.276v.961c0 .268-.22.504-.488.504h-6.032a.494.494 0 0 1-.489-.504v-.3c1.386.032 5.04.127 5.308 0 .268-.14 0-.865-.126-1.149h1.323a.49.49 0 0 1 .488.488h.016Z"
      />
      <Path
        fill="#009CDE"
        d="M76.021 76.655H51.404l.157-1.291.74-5.639h22.807l.74 5.639.173 1.291Z"
      />
      <Path
        fill="#111"
        d="M76.021 76.655H51.404l.157-1.291h24.287l.173 1.291Z"
      />
      <Path
        fill="#009CDE"
        d="M76.383 76.655H51.042a.981.981 0 0 0-.977.977c0 .535.441.976.977.976h25.341a.981.981 0 0 0 .977-.976.981.981 0 0 0-.977-.977ZM78.258 46.022l-6.253 6.095 1.48 8.615c.205 1.166-1.023 2.048-2.063 1.496l-7.733-4.063-7.733 4.063a1.425 1.425 0 0 1-1.56-.157 1.397 1.397 0 0 1-.504-1.355l1.481-8.615-6.253-6.095c-.85-.82-.378-2.268.788-2.426l8.646-1.26 3.86-7.827c.283-.583.881-.85 1.448-.788.441.047.882.315 1.103.788l3.858 7.827 8.647 1.26c1.166.174 1.638 1.607.788 2.426v.016Z"
      />
      <Path
        fill="#F3F3F3"
        d="M63.185 37.8c1.087 0-1.087 6.6-2.425 6.269-1.34-.331 1.496-6.269 2.425-6.269ZM54.491 45.06c-.33.725 4.347 1.087 4.426.505.078-.583-4-1.418-4.426-.504Z"
      />
    </Svg>
  );
}

export function linkExistingAccountStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    title: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f2,
    },
    container: {
      flex: 1,
      padding: Styles.Spacing.m3,
    },
  };
}

export function actionLinkText(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    actionLinkText: {
      color: '#003349',
      fontSize: 16,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    actionlinkView: {
      alignSelf: 'flex-start',
      borderBottomColor: Styles.primaryColor,
      borderBottomWidth: 1.5,
    },
  };
}

export function getBodyTextStyles(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    bodyText: {
      color: Styles.Colors.PayNew.black01,
      fontSize: 18,
      fontWeight: '400',
      marginBottom: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m2,
      paddingStart: Styles.Spacing.m3 + Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontStyle: 'normal',
    },
    supportingText: {
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: '400',
      color: Styles.Colors.PayNew.darkHuesBase03,
      marginBottom: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m2,
      paddingStart: Styles.Spacing.m3 + Styles.Spacing.m2,
    },
    forgotPin: {
      color: Styles.Colors.PayNew.black01,
      fontSize: 16,
      fontWeight: '400',
      marginBottom: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m2,
      paddingStart: Styles.Spacing.m3 + Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.aeonikRegular,
      marginRight: Styles.Spacing.m1,
    },
    forgotPinDescription: {
      color: Styles.Colors.PayNew.black01,
      fontSize: 16,
      fontWeight: '400',
      marginBottom: Styles.Spacing.m2,
      marginTop: -Styles.Spacing.m2,
      paddingStart: Styles.Spacing.m3 + Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.aeonikRegular,
      marginRight: Styles.Spacing.m1,
      fontStyle: 'italic',
    },
  };
}

export function getWelcomeStatusBarStyleDescriptor(): string {
  return Styles.loginBackground;
}

export function getWelcomeViewBckGrndStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    view: {
      flex: 1,
    },
    overlay: {},
  };
}

export function baseAccountContainerDescriptor(): {
  [key: string]: {[key: string]: number | string};
} {
  return {
    container: {
      paddingBottom: Platform.OS === 'android' ? 10 : 0,
      backgroundColor: Styles.white,
    },
    headerText: {
      marginTop: Styles.Spacing.m3,
      fontSize: Styles.Fonts.f2,
      fontWeight: 'bold',
      color: Styles.darkColor,
    },
  };
}
export function getOneTimeTitle(): string {
  return Localized.Labels.one_time_rewards_Canteentitle;
}
export function getOneTimePayEarnColor(): string {
  return '#009CDE';
}
export function getWelcomeOverLayImage() {
  return null;
}

export function getFocusAwareStatusBar() {
  return null;
}

export function getOrTextDescriptor(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    orText: {
      fontSize: Styles.Fonts.f1,
      textTransform: 'uppercase',
    },
    solidLine: {
      backgroundColor: '#999',
    },
  };
}

export function getLineContainer(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    lineContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical: Styles.Spacing.m1,
    },
  };
}

export function getVersionTextDescriptor(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    versionText: {
      color: Styles.Colors.Canteen.darkGreen,
      marginLeft: Styles.Spacing.m3,
    },
  };
}
export function getLogoContainer(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    logoContainer: {
      alignSelf: 'center',
      height: Styles.Heights.h3,
      justifyContent: 'center',
      paddingTop: Styles.Spacing.m1,
      marginTop: -80,
      marginBottom: -20,
      flex: 1,
    },
  };
}

export function getWelcomeButtonStyles(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    topButton: {
      marginTop: Styles.Spacing.m3,
      alignSelf: 'auto',
      paddingVertical: Styles.Spacing.m1,
    },
  };
}

export function getContainer(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    container: {
      marginTop: 120,
    },
  };
}

export function locationCodebgColor(): string {
  return '#066DFF';
}

export function getStatusSystem(): string {
  return Localized.Labels.company_status_system;
}
export function getOnBoardingButton(
  buttonText: string,
  handleClick: () => void,
  disabled?: boolean,
): JSX.Element {
  return (
    <RoundedAppButton
      buttonViewStyle={{height: 43, width: 312}}
      textStyle={[
        {
          fontSize: Styles.Fonts.f1 + 2,
          fontWeight: '700',
        },
        getSnackDescriber().snackDetailsBtnFontSTyle(),
      ]}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
      isDisabled={disabled}
      inActiveButtonViewStyle={{
        backgroundColor: Styles.Colors.PayNew.darkHuesBase04,
        height: 43,
        width: 312,
      }}
      inActiveTextStyle={{fontSize: Styles.Fonts.f1 + 2}}
      buttonType={RoundedAppButtonType.Solid}
      titleText={buttonText}
      accessibilityLabelValue={buttonText}
      aria-label={buttonText}
      onPress={handleClick}
    />
  );
}

export function getLocationCodeText(
  state,
  maxCharacters,
  doSetState,
  locationNote,
) {
  return (
    <AllyTextInput
      accessible={true}
      accessibilityLabel={Localized.Labels.location_code}
      accessibilityHint={Localized.Labels.please_enter_location_code}
      accessibilityValue={{text: state.locationCode}}
      label={Localized.Labels.location_code}
      value={state.locationCode}
      onChangeText={doSetState}
      autoCapitalize="none"
      showHelperText={true}
      helperText={locationNote}
      maxLength={maxCharacters}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
      helperStyle={{
        fontFamily: Styles.FontFamily.robotoRegular,
        fontSize: 14,
        fontWeight: '400',
      }}
    />
  );
}

export function getGoToSettings() {
  return Localized.Buttons.go_to_settings;
}

export default {
  linkExistingAccountStyleDescriptor,
  getWelcomeStatusBarStyleDescriptor,
  getWelcomeViewBckGrndStyleDescriptor,
  baseAccountContainerDescriptor,
  getOrTextDescriptor,
  getVersionTextDescriptor,
  seeMoreDetailtxt,
  getGoToSettings,
  renderPointsToNextRewardLbl,
  getLogoContainer,
  getLetsGoStyle,
  getTrailBlazerIcon,
  getContainer,
  getWelcomeOverLayImage,
  getFocusAwareStatusBar,
  getLineContainer,
  getEliteBuyerTier,
  onboardingLinklocationSuccessTextColor,
  getWelcomeButtonStyles,
  renderYourBalanceRewardLbl,
  locationCodebgColor,
  getOnBoardingButton,
  actionLinkText,
  getRewardsIcon,
  getManualLinktxt,
  getBodyTextStyles,
  getStatusSystem,
  getOneTimePayEarnColor,
  getMemberTier,
  getOneTimeTitle,
  getLocationCodeText,
  getRedeemColor,
  getSmartShopper,
};
